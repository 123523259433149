import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, Outlet, useLocation } from "react-router-dom";

export default function AuthLayouts() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const getTitle = () => {
    switch (location.pathname) {
      case "/signin":
        return "Signin";
      case "/forget":
        return "Forgot";
      case "/signup":
        return "Signup";
      case "/trial":
        return "Trial SignUp";
      default:
        return "Default Title";
    }
  };
  return (

    <div className="login__wrapper">
      <Helmet>
        <title>{` ${getTitle()} | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>
      <nav className="navbar navbar-expand-lg navbar-light login_navbar_section">
        <div className="container navbarBox">
          <Link to="/" className="navbar-brand">
            <img src={process.env.REACT_APP_BRAND__WHITE_LOGO} alt="" />
          </Link>
          <Link to="/">
            <button className="btn btn-md btn-primary btn-back">
              Back to Home
            </button>
          </Link>
        </div>
      </nav>
      <div className="outletPages">
        <Outlet />
      </div>
    </div>

  );
}
