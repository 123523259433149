import { toast } from "react-toastify";
import { api } from "../../../customAxios";

export const UpdatedTaskObject = (editData, isUpdateSubtask) => {
  const taskObject = {
    task_name: editData.task_name,
    task_priority: editData.task_priority,
    task_due_date: editData.task_due_date,
    status_id: editData.status_id,
    status_name: editData.status_name,
    status_completed: editData.status_completed,
    task_completed_at: editData.task_completed_at,
    task_story_point: editData.task_story_point,
    assignees: editData.assignees,
    comments: editData.comments.length,
    sprintName: editData.sprintName,
  };
  if (!isUpdateSubtask) {
    taskObject.subtasks = editData.subTasks;
  }

  return taskObject;
};

export const prevCount = (task) => {
  let countB = 0;
  let countT = 0;
  let countE = 0;
  if (task?.task_type === "B") {
    countB++;
  } else if (task?.task_type === "T") {
    countT++;
  } else if (task?.task_type === "E") {
    countE++;
  }
  if (task?.subtasks) {
    task?.subtasks.forEach((subtask) => {
      if (subtask?.task_type === "B") {
        countB++;
      } else if (subtask?.task_type === "T") {
        countT++;
      }
    });
  }
  return { countB, countT, countE };
};
export const newCount = (updatedTask) => {
  let updatedCountB = 0;
  let updatedCountT = 0;
  let updatedCountE = 0;
  if (updatedTask?.task_type === "B") {
    updatedCountB++;
  } else if (updatedTask?.task_type === "T") {
    updatedCountT++;
  } else if (updatedTask?.task_type === "E") {
    updatedCountE++;
  }
  if (updatedTask?.subTasks) {
    updatedTask?.subTasks.forEach((subtask) => {
      if (subtask?.task_type === "B") {
        updatedCountB++;
      } else if (subtask?.task_type === "T") {
        updatedCountT++;
      }
    });
  }
  return { updatedCountB, updatedCountT, updatedCountE };
};
export const selectTempFiles = (event, setProgress, setFileList) => {
  setProgress(30);
  const input = event.target;
  const selectedFiles = Array.from(input.files);
  const oversizedFiles = selectedFiles.filter(
    (file) => file.size > 2 * 1024 * 1024
  );
  if (oversizedFiles.length > 0) {
    toast.error("File size exceeds the limit of 2 MB");
    input.value = "";
    setProgress(100);
    return;
  }
  const formData = new FormData();
  selectedFiles.forEach((file) => {
    formData.append("file", file);
  });
  api
    .post("temp/files", formData)
    .then((response) => {
      setFileList((prevFileList) => [...prevFileList, response?.data]);
      setProgress(60);
    })
    .catch((error) => {
      console.error("Error uploading files:", error);
    })
    .finally(() => {
      setProgress(100);
      input.value = "";
    });
};

export const launchLightBox = (
  file,
  fileArray,
  setOpenLightBox,
  setLightBoxImages
) => {
  const allowedExtensionsRegex = /\.(png|jpg|jpeg|gif|bmp|svg)$/i;
  const isAllowedFile = allowedExtensionsRegex.test(file.name);

  if (!isAllowedFile) {
    window.open(file.name);
    return;
  }

  const lightBoxImagesArray = fileArray
    .filter((file) => allowedExtensionsRegex.test(file.name))
    .map((file) => ({
      src: file.name,
      alt: file.name,
    }));
  const selectedIndex = fileArray?.findIndex((item) => item === file);
  const reorderedImages = [
    ...lightBoxImagesArray.slice(selectedIndex),
    ...lightBoxImagesArray.slice(0, selectedIndex),
  ];

  setLightBoxImages(reorderedImages);
  setOpenLightBox(true);
};
export const closeLightBox = (setLightBoxImages, setOpenLightBox) => {
  setLightBoxImages([]);
  setOpenLightBox(false);
};

let delayed;
export const barChartLoadAnimation = {
  onComplete: () => {
    delayed = true;
  },
  delay: (context) => {
    let delay = 0;
    if (context.type === "data" && context.mode === "default" && !delayed) {
      delay = context.dataIndex * 300 + context.datasetIndex * 60;
    }
    return delay;
  },
};

export const handleUpdateCurrentListTasks = (type, taskDetail, subItemId) => {
  const isListExist = localStorage.getItem("currentTaskList")
  if (isListExist) {
    if (type === "add") {
      let currentTaskList = JSON.parse(localStorage.getItem("currentTaskList"));
      // Find the index where the last subtask ends
      const lastSubtaskId = taskDetail?.subTasks?.[taskDetail?.subTasks?.length - 1]?.task_id;
      const lastIndex = currentTaskList.indexOf(lastSubtaskId);

      if (lastIndex !== -1 && !currentTaskList.includes(subItemId)) {
        // Insert the new subtask ID right after the last subtask ID
        currentTaskList.splice(lastIndex + 1, 0, subItemId);
        localStorage.setItem("currentTaskList", JSON.stringify(currentTaskList));
      }
    } else {
      const currentTaskList = JSON.parse(localStorage.getItem("currentTaskList"));
      const updatedList = currentTaskList?.filter((item) => item !== subItemId);
      localStorage.setItem("currentTaskList", JSON.stringify(updatedList))
    }
  }
}