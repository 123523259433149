import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import Group from "../../../assets/images/landingDashboard.png";
import About from "./About";
import Carousel from "./Carousel";
import Contact from "./Contact";
import Features from "./Features";
import Graph from "./Graph";

export default function Home() {
  const navigate = useNavigate();
  const [openViewFile, setOpenViewFile] = useState(false);

  // Function to handle closing the modal
  const handleCloseViewFile = () => {
    setOpenViewFile(false);
  };

  // Function to handle opening the modal
  const handleOpenViewFile = () => {
    setOpenViewFile(true);
  };

  // Function to navigate to the sign-up page
  const signUp = () => {
    window.scrollTo(0, 0);
    navigate("/signup");
  };

  return (
    <>
      <div className="home_page_component mb-5">
        <section className="main_content home_Container">
          <div className="container__land">
            <div className="row align-items-center">
              <div className="col-md-6 col-12 contant_section">
                <h1 className="contant_heading h1s fs-38 responsive_heading">
                  Streamline, Collaborate, Iterate, and Deliver high quality
                  Projects with ScrumSlate
                </h1>
                <p className="paragraph mt-3 responsive_text">
                  A powerful tool for efficiently managing projects,
                  centralizing tasks, timelines, and delivering results that
                  exceed expectations. Break free from the constraints of
                  outdated methods and unlock your team's true potential with
                  ScrumSlate.
                </p>
                <br />
                <div className="d-flex home_buttons">

                  <button className="btn btn-primary fix-buton" onClick={signUp}>
                    Get Started
                  </button>
                  <button
                    className="btn fix-buton mx-3 btn-cancel"
                    onClick={handleOpenViewFile}
                  >
                    <i className="fa fa-play mx-2" aria-hidden="true"></i> Watch
                    Video
                  </button>
                </div>

              </div>
              <div className="col-md-6 col-12 image_section">
                <div className="home__image__section">
                  <img src={Group} alt="Group" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Conditionally render the modal */}
      {openViewFile && (
        <Modal
          size="lg"
          show={openViewFile}
          // onHide={handleCloseViewFile}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="pt-5 pl-5 add-member_modal"
          style={{ zIndex: "1500" }}
        >
          <div className="container">
            <div className="main-modal">
              <Modal.Header>
                <Modal.Title></Modal.Title>
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon
                    icon={faClose}
                    onClick={handleCloseViewFile}
                    className="cursor-pointer"
                  />
                </div>
              </Modal.Header>
              <Modal.Body className="vedio_modal">
                {/* Embed the iframe video directly */}
                <iframe
                  src="https://player.vimeo.com/video/944427680?h=22a9defd8f"
                  className="landingPageVedio"
                  // width="100%"
                  // height="400"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  title="Scrum Slate"
                ></iframe>
              </Modal.Body>
            </div>
          </div>
        </Modal>
      )}

      {/* Other components */}
      <Graph />
      <Features />
      <About />
      <Carousel />
      <Contact />
    </>
  );
}
