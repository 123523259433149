import React, { useEffect } from "react";
import Groups from "../../../assets/images/Group 1486.png";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";

export default function About() {
  const navigate = useNavigate();

  function about() {
    window.scrollTo(0, 0);
    navigate("/about-us");
  }

  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  const { ref: buttonRef, inView: buttonInView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView) {
      document.body.classList.add("hide-scrollbar");
    } else {
      document.body.classList.remove("hide-scrollbar");
    }

    return () => {
      document.body.classList.remove("hide-scrollbar");
    };
  }, [inView]);

  return (
    <div ref={ref}>
      <section
        className="about mb-5 mt-5 py-5"
        style={{ visibility: inView ? "visible" : "hidden", opacity: inView ? 1 : 0 }}
      >
        <div className="container__land">
          <div
            className={`row landing_page_row align-items-center ${inView ? "animate__animated animate__fadeInUp" : ""
              }`}
          >
            <div className="col-md-6 ">
              <div className="about_img">
                <img src={Groups} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <h2 className="mt-5 pt-2 responsive_heading">About Us</h2>
              <p className="paragraph mt-3 responsive_text">
                At Scrum Slate, our mission is to develop a tool that is
                specifically dedicated to Agile Project Management teams. We
                understand it is harder to achieve the target goals when you
                don’t have the right tool. With Scrum Slate you get the right
                features that support sprint planning, backlog management, bug
                tracking and iterative development cycles to ensure your team
                stays on track.
              </p>
              <br />
              <button
                className={`btn btn-primary fix-buton ${buttonInView ? "animate__animated animate__fadeIn" : ""}`}
                onClick={about}
                ref={buttonRef}
              >
                Read More
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
