import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import addTitleImg from "../../../assets/images/extension-page/addTitle.png";
import taskCardImg from "../../../assets/images/extension-page/createTask.png";
import extentionDashboardImg from "../../../assets/images/extension-page/extentiondashboard2.png";
import recordScreen from "../../../assets/images/extension-page/recordScreen.png";
import projectCardImg from "../../../assets/images/extension-page/selectProject.png";
import saveCardImg from "../../../assets/images/extension-page/selectSave.png";
import ExtensionFeatureCard from "./ExtensionFeatureCard";
import ExtensionSelectCard from "./ExtensionSelectCard";

function Extension() {
  const [selectedId, setSelectedId] = useState("project");

  const sections = [
    {
      id: "project",
      image: projectCardImg,
      content: (
        <>
          <h4 className="extention_feature_heading">
            Navigate Your Projects With Ease.
          </h4>
          <div className="extention_feature_content">
            <p className="pt-2">
              Instantly choose the project where you want to add the task. This
              is an excellent way to save time when you're juggling multiple
              tasks and ideas.
            </p>
          </div>
        </>
      ),
    },
    {
      id: "task",
      image: taskCardImg,
      content: (
        <>
          <h4 className="extention_feature_heading">
            Task Or Video? Your Choice.
          </h4>
          <div className="extention_feature_content">
            <p className="pt-2">
              With Scrum Slate extension, you can opt for either tasks or video
              recording only. Standalone videos serve as versatile tasks,
              allowing you to share them with others.
            </p>
          </div>
        </>
      ),
    },
    {
      id: "record",
      image: recordScreen,
      content: (
        <>
          <h4 className="extention_feature_heading">
            Capture, Enhance, And Express.
          </h4>
          <div className="extention_feature_content">
            <p class="pt-2">
              Don't miss any details with the Scrum Slate Chrome extension.
              Effortlessly capture bugs, websites, or any content, and
              effectively convey ideas through recorded.
            </p>
          </div>
        </>
      ),
    },
    {
      id: "addTitle",
      image: addTitleImg,
      content: (
        <>
          <h4 className="extention_feature_heading">
            Name It For Tracking & Organizing.
          </h4>
          <div className="extention_feature_content">
            <p class="pt-2">
              After recording, assign a specific title to organize your tasks
              and videos, enabling you to quickly locate your content in the
              future.
            </p>
          </div>
        </>
      ),
    },
    {
      id: "saveIt",
      image: saveCardImg,
      content: (
        <>
          <h4 className="extention_feature_heading">
            Save Ideas And Share With Team.
          </h4>
          <div className="extention_feature_content">
            <p class="pt-2">
              Save your task or recording for instant access and team sharing.
              add comments to start discussions and enhance collaboration among
              team members.
            </p>
          </div>
        </>
      ),
    },
  ];

  const selectedSection = sections.find((section) => section.id === selectedId);

  const getExtainshion = () => {
    window.open(
      "https://chromewebstore.google.com/detail/scrum-slate-%E2%80%93-screen-reco/fieiojigoecblnfonmifhapdmihdcdjo?hl=en-GB&utm_source=ext_sidebar"
    );
  };

  const { ref: headerButtonRef, inView: headerButtonInView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { ref: footerButtonRef, inView: footerButtonInView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <>
      <div className="extension_header">
        <div className="container__land">
          <div className="text-center top-text top-text-features">
            <h1 className="feature_page_heading responsive_heading pb-2">
              Record And Generate Tasks At The Speed Of Thoughts With Our Chrome
              Extension.
            </h1>
            <div className="w-50 m-auto">
              <p className="mt-1 color_unset ">
                Eliminate the hassle of navigating applications and projects.
                Use extension to effortlessly record your screen, explain tasks,
                and create them with just one click.
              </p>
            </div>
            <button
              className={`btn btn-primary extention_get_btn mt-3 ${headerButtonInView ? "animate__animated animate__fadeIn" : ""
                }`}
              onClick={getExtainshion}
              ref={headerButtonRef}
            >
              Get it Now
            </button>
            <br />
          </div>
        </div>
      </div>
      <div className="extention_dashboard_img">
        <img src={extentionDashboardImg} className="w-100" alt="" />
      </div>
      <div className="exetension_work_heading">
        <h2 className="text-center">How It Works</h2>
        <ExtensionSelectCard
          setSelectedId={setSelectedId}
          selectedId={selectedId}
        />
      </div>
      <ExtensionFeatureCard
        image={selectedSection.image}
        content={selectedSection.content}
      />
      <div className="container">
        <div className="getStartedFree ">
          <div className="bgColor">
            <h5 className="get_start_text">Get started from today its free</h5>
            <button
              className={`btn btn-primary extention_get_btn fw-2 ${footerButtonInView ? "animate__animated animate__fadeIn" : ""
                }`}
              onClick={getExtainshion}
              ref={footerButtonRef}
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Extension;
