import React from 'react';
import Contact from '../landingPage/Contact';
import Form from './Form';

export default function ContactUs() {
    return (
        <>
            <div className='contectus_page_section'>
                <div className='container__land'>
                    <div className="text-center top-text">
                        <h1 className='responsive_heading'>
                            Contact Us
                        </h1>
                        <p className=' mt-1 responsive_text'>Home/Contact Us</p><br />
                    </div>
                </div>
            </div>

            <Form />
            <Contact />
        </>
    )
}
