import { Popover, Tooltip } from "@mui/material";
// import { functiondisable, functionenable } from "../../helpers/helper";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { editTask } from "../../Redux/Slice/Task";
import { formatName } from "../../Utils/helpers";
import unassign from "../../assets/images/Group 1129@2x.png";
import CustomAvatar from "./Task/CustomAvatar";
import CustomPopOverMenu from "./Task/CustomPopOverMenu";
import { api } from "../../customAxios";

const TaskAssigned = (props) => {
  const {
    allUsers,
    assignees,
    handleUpdateTask,
    isTaskList,
    taskId,
    isSmallScreen,
    isTableView,
    isKanban,
    projectId,
    sprintId,
    taskListId,
    taskDetail,
    isSubtaskview,
    refreshPage,
    isInline,
    setProgress,
    isTestCase,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const [selectedName, setSelectedName] = useState();
  const [selectedAssignee, setSelectedAssignee] = React.useState();

  const handleClick = (event) => {
    if (taskDetail?.userPermission?.can_i_edit === false) {
      toast.info("You don't have access to update task", {
        position: "bottom-right",
      });
      return;
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  React.useEffect(() => {
    if (assignees && assignees.length !== 0 && isTaskList) {
      setSelectedAssignee(assignees?.[0]?.id || null);
      setSelectedName(assignees?.[0]?.name);
    } else if (!isTaskList) {
      setSelectedAssignee(assignees?.[0]?.id || null);
    } else {
      setSelectedAssignee(null);
    }
  }, [assignees]);

  const handleSelectOption = (user) => {
    if (
      user?.id === selectedAssignee ||
      (user === undefined && selectedAssignee === null)
    ) {
      return;
    }
    const assigneesArray = user?.id !== null ? [user?.id] : [null];
    if (taskId) {
      if (user) {
        setSelectedAssignee(user?.id);
        setSelectedName(user?.name);
        setProgress(30);
        const payload = new FormData();
        payload.append(`assignees`, JSON.stringify(assigneesArray));
        const apiCall = isTestCase
          ? api.post(`/test-cases/${taskDetail?.id}`, payload)
          : dispatch(editTask({ taskId, payload }));
        apiCall
          .then((resp) => {
            setProgress(60);
            // if (isTestCase && refreshPage) {
            //   refreshPage();
            // } else
            if (isSubtaskview) {
              refreshPage(null, resp?.payload?.task);
            } else if (refreshPage) {
              refreshPage(
                taskId,
                projectId,
                null,
                null,
                sprintId,
                taskListId,
                resp?.payload?.task,
                "added change inline"
              );
            }
            setProgress(100);
            toast.success("Task assignee changed successfully!");
          })
          .finally(() => {
            setProgress(100);
          });
      } else {
        if (handleUpdateTask) {
          handleUpdateTask("assign", [null]);
        } else if (isInline || isKanban) {
          setProgress(30);
          const payload = new FormData();
          payload.append("assignees", JSON.stringify([null]));
          dispatch(editTask({ taskId, payload }))
            .then((resp) => {
              if (refreshPage) {
                refreshPage(
                  taskId,
                  projectId,
                  null,
                  null,
                  sprintId,
                  taskListId,
                  resp?.payload?.task,
                  "added change inline"
                );
              }
              setProgress(60);
            })
            .finally(() => {
              setProgress(100);
            });
        }
        setSelectedAssignee(null);
        toast.success("Task assignee changed successfully!");
      }
    } else {
      toast.success("Task assignee changed successfully!");
      if (user) {
        setSelectedAssignee(user?.id);
        handleUpdateTask("assign", user.id !== null ? [user.id] : [null]);
      } else {
        handleUpdateTask("assign", [null]);
        setSelectedAssignee(null);
      }
    }

    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleScroll = () => {
    handleClose();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <React.Fragment>
      {isKanban ? (
        allUsers?.find((user) => user.id === selectedAssignee)?.image ? (
          <div
            title={`${allUsers?.find((user) => user.id === selectedAssignee)?.name
              }`}
            className={`img border_radius10 ${taskDetail?.userPermission?.can_i_edit === false
                ? "not-allowed-disable"
                : "add_cursor"
              } size_cover`}
            style={{
              backgroundImage: `url('${allUsers?.find((user) => user.id === selectedAssignee)?.image
                }')`,
            }}
            onClick={handleClick}
          ></div>
        ) : selectedName && selectedAssignee ? (
          <span
            onClick={handleClick}
            className={`p-0 ${taskDetail?.userPermission?.can_i_edit === false
                ? "not-allowed-disable"
                : ""
              }`}
          >
            <CustomAvatar
              name={selectedName}
              height={isKanban ? 24 : 26}
              width={isKanban ? 24 : 26}
              backgroundColor={
                allUsers?.find((user) => user.id === selectedAssignee)
                  ?.profile_color || ""
              }
              cursor={
                taskDetail?.userPermission?.can_i_edit === false
                  ? "not-allowed"
                  : "pointer"
              }
            />
          </span>
        ) : (
          <div
            className={`unassigned_image ${isSmallScreen ? `col-md-1 w-24 ${isTableView ? "" : "mt-14"}` : ""
              } ${taskDetail?.userPermission?.can_i_edit === false
                ? "not-allowed-disable"
                : "add_cursor"
              }`}
            onClick={handleClick}
          >
            <img alt="" src={unassign} />
          </div>
        )
      ) : isTaskList && allUsers?.length > 0 ? (
        selectedAssignee !== null ? (
          <div
            className={`${isSmallScreen
                ? `w-12 col-md-1 w-18 ${isTableView ? "" : "mt-14"}`
                : "task_items_member"
              } ${taskDetail?.userPermission?.can_i_edit === false
                ? "not-allowed-disable"
                : ""
              }`}
          >
            {allUsers?.find((user) => user.id === selectedAssignee)?.image ? (
              <div
                title={`${allUsers?.find((user) => user.id === selectedAssignee)?.name
                  }`}
                className={`task_items_list member size_cover ${taskDetail?.userPermission?.can_i_edit === false
                    ? ""
                    : "add_cursor"
                  } ${!isTableView ? "border_radius10" : ""} ${isSubtaskview ? "subtask_assignee" : ""
                  }`}
                style={{
                  backgroundImage: `url('${allUsers?.find((user) => user.id === selectedAssignee)
                      ?.image
                    }')`,
                  border: "1px solid #979797",
                  height: isTableView ? "26px" : "",
                  width: isTableView ? "26px" : "",
                  margin: isTableView ? "auto" : "",
                }}
                onClick={handleClick}
              ></div>
            ) : (
              <span onClick={handleClick}>
                <CustomAvatar
                  name={selectedName || ""}
                  height={26}
                  width={26}
                  cursor={
                    taskDetail?.userPermission?.can_i_edit === false &&
                    "not-allowed"
                  }
                  backgroundColor={
                    allUsers?.find((user) => user.id === selectedAssignee)
                      ?.profile_color || ""
                  }
                  isMarginAuto={isTableView ? true : ""}
                />
              </span>
            )}
          </div>
        ) : (
          <>
            {isSmallScreen ? (
              <div className={`col-md-1 w-12 ${isTableView ? "" : "mt-14"}`}>
                <div
                  className={`unassigned_image ${taskDetail?.userPermission?.can_i_edit === false
                      ? "not-allowed-disable"
                      : "add_cursor"
                    }`}
                  onClick={handleClick}
                  style={{ margin: isTableView ? "auto" : "" }}
                >
                  <img alt="" src={unassign} />
                </div>
              </div>
            ) : (
              <div
                className={`unassigned_image ${taskDetail?.userPermission?.can_i_edit === false
                    ? "not-allowed-disable"
                    : "add_cursor"
                  }`}
                onClick={handleClick}
                style={{ margin: isTableView ? "auto" : "" }}
              >
                <img alt="" src={unassign} />
              </div>
            )}
          </>
        )
      ) : selectedAssignee !== null ? (
        <span
          className={`newFeature selected-images-row w_168px ${taskDetail?.userPermission?.can_i_edit === false &&
            "not-allowed-disable"
            }`}
          onClick={handleClick}
        >
          <div
            className={`d-flex align-items-center coursor-pointer ${taskDetail?.userPermission?.can_i_edit === false &&
              "cursor-not-allowed"
              }`}
          >
            {allUsers?.find((user) => user.id === selectedAssignee)?.image ? (
              <>
                <div
                  className="size_cover userImages"
                  style={{
                    backgroundImage: `url('${allUsers?.find((user) => user.id === selectedAssignee)
                        ?.image
                      }')`,
                  }}
                ></div>
                <span className="ms-2 text-capitalize">
                  <Tooltip
                    title={
                      allUsers?.find((user) => user.id === selectedAssignee)
                        ?.name
                    }
                    arrow
                    placement="top"
                  >
                    {formatName(
                      allUsers?.find((user) => user.id === selectedAssignee)
                        ?.name
                    )}
                  </Tooltip>
                </span>
              </>
            ) : (
              <div
                className={`d-flex ${taskDetail?.userPermission?.can_i_edit === false &&
                  "cursor-not-allowed"
                  }`}
              >
                <CustomAvatar
                  name={
                    allUsers?.find((user) => user.id === selectedAssignee)
                      ?.name || ""
                  }
                  backgroundColor={
                    allUsers?.find((user) => user.id === selectedAssignee)
                      ?.profile_color || ""
                  }
                  cursor={
                    taskDetail?.userPermission?.can_i_edit === false &&
                    "not-allowed"
                  }
                />
                <span className="ms-2 text-capitalize">
                  <Tooltip
                    title={
                      allUsers?.find((user) => user.id === selectedAssignee)
                        ?.name
                    }
                    arrow
                    placement="top"
                  >
                    {formatName(
                      allUsers?.find((user) => user.id === selectedAssignee)
                        ?.name
                    )}
                  </Tooltip>
                </span>
              </div>
            )}
          </div>
        </span>
      ) : (
        <div
          className={`d-flex align-items-center coursor-pointer w_168px ${taskDetail?.userPermission?.can_i_edit === false &&
            "not-allowed-disable"
            }`}
          onClick={handleClick}
        >
          <span className="unassigned_image">
            <img alt="" src={unassign} />
          </span>

          <span className="ms-2 align-self-center text-capitalize">
            Unassigned
          </span>
        </div>
      )}

      <Popover
        anchorEl={anchorEl}
        className=""
        id="account-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        disableScrollLock={true}
      >
        <CustomPopOverMenu
          setSelectedUsers={setSelectedAssignee}
          selectedUsers={selectedAssignee}
          allUsers={allUsers}
          handleChange={handleSelectOption}
          isMultiple={false}
          isAddUnassign={true}
        />
      </Popover>
    </React.Fragment>
  );
};

export default TaskAssigned;
