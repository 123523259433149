import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useInView } from "react-intersection-observer";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { api } from "../../../customAxios";
import constants from "../../../Utils/constants";

export default function Contact() {
    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required("Please enter a valid email address")
            .matches(constants.EMAIL_REGEX, "Please enter a valid email address"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });

    const handleEmail = async (data) => {
        setIsLoading(true);
        const payload = {
            email: data.email,
        };
        try {
            await api.post(`/subcribe-for-newsletter`, payload);
            toast.success(`Success! You've subscribed to the Newsletter`);
            reset();
        } catch (err) {
            toast.error("Failed to subscribe. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    };
    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true
    });

    return (
        <div>
            <section className="contact" ref={ref}>
                <div className={`container__land text-center ${inView ? "animate__animated animate__fadeInUp" : ""
                    }`} style={{ visibility: inView ? 'visible' : 'hidden', opacity: inView ? 1 : 0 }}>
                    <h2 className="responsive_heading">Subscribe For Update</h2>
                    <div className="paragraph-div">
                        <p className="paragraph mt-4 responsive_text">
                            Be the first to know about our latest news, offers, and insights.
                            Join our community and subscribe for updates today
                        </p>
                    </div>
                    <br />
                    <form onSubmit={handleSubmit(handleEmail)}>
                        <div className="subscibe_form">
                            <div className="in">
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    className="form-control form-control__input"
                                    {...register("email")}
                                />
                                <button
                                    type="submit"
                                    className={`btn btn-primary btn-sm ${isLoading ? "btn-loader_email" : ""
                                        }`}
                                    disabled={isLoading || !isValid}
                                >
                                    {isLoading ? (
                                        <ClipLoader color="#fff" size={20} loading={isLoading} />
                                    ) : (
                                        "Subscribe"
                                    )}
                                </button>
                            </div>
                            {errors.email && (
                                <span className="danger-color error-msg subscibe_form_error_msg">
                                    {errors.email.message}
                                </span>
                            )}
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
}