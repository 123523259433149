import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

export default function AboutCards() {
  const { ref: ARef, inView: AInView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { ref: BRef, inView: BInView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { ref: CRef, inView: CInView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { ref: DRef, inView: DInView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { ref: ERef, inView: EInView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { ref: FRef, inView: FInView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { ref: GRef, inView: GInView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  useEffect(() => {
    if (AInView || BInView || CInView || DInView || EInView || FInView || GInView) {
      document.body.classList.add("hide-scrollbar");
    } else {
      document.body.classList.remove("hide-scrollbar");
    }

    return () => {
      document.body.classList.remove("hide-scrollbar");
    };
  }, [AInView, BInView, CInView, DInView, EInView, FInView, GInView]);
  return (
    <div>
      <div className="about_us_section">
        <div className="row cust about_row">
          <div className="col-md-3 big_grid ">
            <div className="big_circle_box">
              <div className="big-circle">
                <h1 className="text-primary why-text">
                  Why
                  Choose
                  Us
                </h1>
              </div>
            </div>
          </div>
          <div className="col-md-9 width_100 ">
            <div className="choose_us_section">
              <div className="english-cards a-one" ref={ARef} style={{ visibility: AInView ? 'visible' : 'hidden', opacity: AInView ? 1 : 0 }}>
                <div className="alphabit_cards text-primary">
                  <h1>A</h1>
                </div>
                <div className={`cads ${AInView ? "animate__animated animate__fadeInRight" : ""}`}>
                  <p className="paragraph responsive_small_heading">
                    <b>All-In-One Scrum Tool:</b>
                  </p>
                  <p className="pargraph-body responsive_text mt-2">
                    Scrum Slate is well rounded in project management. Users can
                    break down user stories into small, testable, trackable,
                    manageable, estimable, independent tasks and subtasks.
                  </p>
                </div>
              </div>

              <div className="english-cards b-one" ref={BRef} style={{ visibility: BInView ? 'visible' : 'hidden', opacity: BInView ? 1 : 0 }}>
                <div className="alphabit_cards text-primary">
                  <h1>B</h1>
                </div>
                <div className={`cads ${BInView ? "animate__animated animate__fadeInRight" : ""}`}>
                  <p className="paragraph responsive_small_heading">
                    <b>Smart AI Feature:</b>
                  </p>
                  <p className="pargraph-body responsive_text mt-2">
                    Our advanced AI features make project management easy, time
                    saving and effortless. Refine task details, Identify areas of
                    risk, generate reports and stay ahead of deadlines
                  </p>
                </div>
              </div>
              <div className="english-cards c-one" ref={CRef} style={{ visibility: CInView ? 'visible' : 'hidden', opacity: CInView ? 1 : 0 }}>
                <div className="alphabit_cards text-primary">
                  <h1>C</h1>
                </div>
                <div className={`cads ${CInView ? "animate__animated animate__fadeInRight" : ""}`}>
                  <p className="paragraph responsive_small_heading">
                    <b>Predict Dependencies in Advance:</b>
                  </p>
                  <p className="pargraph-body responsive_text mt-2">
                    With advance sprint reports and tracking features stay one
                    step ahead and identify the high priority quick wins for your
                    sprints.
                  </p>
                </div>
              </div>
              <div className="english-cards d-one" ref={DRef} style={{ visibility: DInView ? 'visible' : 'hidden', opacity: DInView ? 1 : 0 }}>
                <div className="alphabit_cards text-primary">
                  <h1>D</h1>
                </div>
                <div className={`cads ${DInView ? "animate__animated animate__fadeInRight" : ""}`}>
                  <p className="paragraph responsive_small_heading">
                    <b>Customization & Automation:</b>
                  </p>
                  <p className="pargraph-body responsive_text mt-2">
                    With flexible customization options and powerful automation
                    features, you can tailor Scrum Slate to meet your specific
                    needs and preferences.
                  </p>
                </div>
              </div>
              <div className="english-cards e-one" ref={ERef} style={{ visibility: EInView ? 'visible' : 'hidden', opacity: EInView ? 1 : 0 }}>
                <div className="alphabit_cards text-primary">
                  <h1>E</h1>
                </div>
                <div className={`cads ${ERef ? "animate__animated animate__fadeInRight" : ""}`}>
                  <p className="paragraph responsive_small_heading">
                    <b>Insightful Dashboards:</b>
                  </p>
                  <p className="pargraph-body responsive_text mt-2">
                    Give your team the visibility they need to track their
                    progress, identify blockers, analyze where they are falling
                    behind and make important decisions.
                  </p>
                </div>
              </div>

              <div className="english-cards f-one" ref={FRef} style={{ visibility: FInView ? 'visible' : 'hidden', opacity: FInView ? 1 : 0 }}>
                <div className="alphabit_cards text-primary">
                  <h1>F</h1>
                </div>
                <div className={`cads ${FInView ? "animate__animated animate__fadeInRight" : ""}`}>
                  <p className="paragraph responsive_small_heading">
                    <b>User-Friendly Interface:</b>
                  </p>
                  <p className="pargraph-body responsive_text mt-2">
                    Unlike other tools that have a clunky and time consuming
                    interface, Scrum Slate is designed with simplicity and ease in
                    mind. Everything is big, clear and key features are just one
                    click away.
                  </p>
                </div>
              </div>
              <div className="english-cards g-one" ref={GRef} style={{ visibility: GInView ? 'visible' : 'hidden', opacity: GInView ? 1 : 0 }}>
                <div className="alphabit_cards text-primary">
                  <h1>G</h1>
                </div>
                <div className={`cads ${GInView ? "animate__animated animate__fadeInRight" : ""}`}>
                  <p className="paragraph responsive_small_heading">
                    <b>Data migration:</b>
                  </p>
                  <p className="pargraph-body responsive_text mt-2">
                    Our streamlined process ensures that your valuable project
                    data is securely transferred, allowing you to pick up right
                    where you left off without missing a beat.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
