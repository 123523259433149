import React, { useEffect } from "react";
import All from "../../../assets/images/Report12.png";
import { useInView } from "react-intersection-observer";

export default function Tasks() {

  const { ref: parent1Ref, inView: parent1InView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { ref: parent2Ref, inView: parent2InView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const { ref: parent3Ref, inView: parent3InView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    if (parent1InView || parent2InView || parent3InView) {
      document.body.classList.add("hide-scrollbar");
    } else {
      document.body.classList.remove("hide-scrollbar");
    }

    return () => {
      document.body.classList.remove("hide-scrollbar");
    };
  }, [parent1InView, parent2InView, parent3InView]);

  return (
    <div>
      <section className="project bgs task_section agile-main-div">
        <div className="container__fearter container-m">
          <div className="text-center" ref={parent1Ref}>
            <h3 className="mian-text responsive_heading">Agile Planning & Tracking </h3>
          </div>
          <div className="row mt-5 mb-5rm align-items-center">
            <div className={`col-md-6  cads__colmn feature-agile ${parent3InView || parent1InView ? "animate__animated animate__fadeInLeft" : ""}`}>
              <div className="crop-container img-iss">
                <img
                  src={All}
                  alt="Cropped"
                  className="cropped-image  mt-2"
                />
                {/* <div className="image"></div> */}
              </div>
            </div>

            <div className="col-md-6 cads__width">
              <div className={`cads new ${parent1InView ? "animate__animated animate__fadeInRight" : ""}`} style={{ visibility: parent1InView ? 'visible' : 'hidden', opacity: parent1InView ? 1 : 0 }}>
                <p className="paragraph responsive_small_heading">
                  <b>Sprints:</b>
                </p>
                <p className="pargraph-body responsive_text mt-2">
                  Define sprint period, set goals, allocate tasks to team
                  members, and monitor progress in real time.
                </p>
              </div>
              <br />
              <div className={`cads ms-4 ${parent2InView ? "animate__animated animate__fadeInRight" : ""}`} ref={parent2Ref} style={{ visibility: parent2InView ? 'visible' : 'hidden', opacity: parent2InView ? 1 : 0 }}>
                <p className="paragraph responsive_small_heading">
                  <b>Backlog:</b>
                </p>
                <p className="pargraph-body responsive_text mt-2">
                  Create task lists in the backlog to smartly plan and
                  strategies your user stories, feature requests, and tasks for
                  future sprints or releases.
                </p>
              </div>
              <br />
              <div className={`cads new ${parent3InView ? "animate__animated animate__fadeInRight" : ""}`} ref={parent3Ref} style={{ visibility: parent3InView ? 'visible' : 'hidden', opacity: parent3InView ? 1 : 0 }}>
                <p className="paragraph responsive_small_heading">
                  <b>Progress Board:</b>
                </p>
                <p className="pargraph-body responsive_text mt-2">
                  Track task movement through various stages on customizable
                  boards to limit work-in-progress, and maximize team’s
                  efficiencies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
