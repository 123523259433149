import { faMicrophone, faStop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

const mimeType = "audio/webm";

const AudioRecorder = (props) => {
  const [permission, setPermission] = useState(false);
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState(null);
  const [audio, setAudio] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [listening, setListening] = useState(false);
  const [recognition, setRecognition] = useState(null);

  useEffect(() => {
    const recognitionInstance = new window.webkitSpeechRecognition();

    recognitionInstance.onresult = (event) => {
      // Remove the transcription part
    };

    recognitionInstance.onerror = (event) => {
      console.error('Speech recognition error:', event.error);
    };

    recognitionInstance.onend = () => {
      setListening(false);
    };

    setRecognition(recognitionInstance);

    return () => {
      // Cleanup: Stop recognition when the component unmounts
      if (recognitionInstance) {
        recognitionInstance.stop();
      }
    };
  }, []);

  useEffect(() => {
    const getMicrophonePermission = async () => {
      if ("MediaRecorder" in window) {
        try {
          const mediaStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
          });
          setPermission(true);
          setStream(mediaStream);
        } catch (err) {
          alert(err.message);
        }
      } else {
        alert("The MediaRecorder API is not supported in your browser.");
      }
    };

    getMicrophonePermission();
  }, []); // Run once on component mount

  const startRecording = () => {
    setRecordingStatus("recording");
    props.setIsLoading(false);
    const media = new MediaRecorder(stream, { type: mimeType });

    mediaRecorder.current = media;

    let localAudioChunks = [];

    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };

    mediaRecorder.current.onstop = () => {
      const audioBlob = new Blob(localAudioChunks, { type: mimeType });

      const audioUrl = URL.createObjectURL(audioBlob);
      var audio_file = new File(
        [audioBlob],
        uuidv4("audio_file_" + Math.floor(Math.random() * 10000)) + ".mp3",
        { type: "audio/mp3" }
      );
      setAudio(audioUrl);
      props.setIsLoading(true);
      props.handleGetAudio(audio_file);
      setAudioChunks([]);
    };

    mediaRecorder.current.start();
  };

  const stopRecording = () => {
    setRecordingStatus("inactive");
    mediaRecorder.current.stop();
  };

  return (
    <div>
      <div className="audio-controls">
        {/* {!permission ? (
          <button className="circle-button" onClick={() => getMicrophonePermission()} type="button">
            <FontAwesomeIcon icon={faMicrophone} />
          </button>
        ) : null} */}
        {permission && recordingStatus === "inactive" && !audio ? (
          <button className="circle-button" onClick={startRecording} type="button">
            <FontAwesomeIcon icon={faMicrophone} />
          </button>
        ) : null}
        {recordingStatus === "recording" ? (
          <button className="circle-button" onClick={stopRecording} type="button">
            <FontAwesomeIcon icon={faStop} />
          </button>
        ) : null}
      </div>
      {audio ? (
        <>
          <div className="col-md-6 border-black">
            <h5>Title</h5>
            <input type="text" name="name" value={props.fileTitle} onChange={(e) => props.setFileTitle(e.target.value)} className={`form-control form-control__input`} />
          </div>
          <div className="audio-player">
            <audio controls src={audio}></audio>
            {/* <a download href={audio}>
            Download Recording
          </a> */}
          </div>
        </>

      ) : null}
    </div>
  );
};

export default AudioRecorder;
