import React, { useState } from "react";
import Call from "../../../assets/images/call.png";
import Icons from "../../../assets/images/icons.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { api } from "../../../customAxios";

export default function Form() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const formData = {
      email: email,
      name: name,
      message: message,
    };
    if (email && name && message) {
      api
        .post("/contact-us", formData)
        .then((response) => {
          toast.success(response.data.message);
          setIsLoading(false);
          setName("");
          setEmail("");
          setMessage("");
          setEmailError("");
          setNameError("");
          setMessageError("");
        })
        .catch(() => {
          toast.error("Form submission failed");
          setIsLoading(false);
        });
    } else {
      setEmailError(email ? "" : "Email is required");
      setNameError(name ? "" : "Name is required");
      setMessageError(message ? "" : "  Message is required");
      setIsLoading(false);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
      setNameError(value ? "" : "Name is required");
    } else if (name === "email") {
      setEmail(value);
      setEmailError(value ? "" : "Email is required");
    } else if (name === "message") {
      setMessage(value);
      setMessageError(value ? "" : "Message is required");
    }
  };

  return (
    <div className="form-divs">
      <section className="forms-dev">
        <div className="container__land">
          <h4>Contact Us</h4>
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex text-center mt-4">
                <div className="img-dev text-center">
                  <img src={Call} className="m-auto" alt="Call" />
                </div>
                <p className="paragra paragraph__content ms-3">
                  +1 929 207 2406
                </p>
              </div>
              <div className="d-flex text-center">
                <div className="img-dev text-center">
                  <img src={Icons} className="m-auto" alt="Icons" />
                </div>
                <p className="paragra paragraph__content ms-3">
                  info@scrumslate.com
                </p>
              </div>
            </div>
            <div className="col-md-6 form_section_column">
              <form onSubmit={handleSubmit}>
                <label className="text-dark">Name</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleInputChange}
                  placeholder={nameError ? nameError : "Enter name"}
                  className={`form-control mt-2 form-control__input f-12 ${nameError ? "is-invalid__alert" : ""
                    }`}
                // required
                />
                <label className="text-dark mt-3">Email</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                  placeholder={emailError ? emailError : "Enter email address"}
                  className={`form-control mt-2 form-control__input f-12 ${emailError ? "is-invalid__alert" : ""
                    }`}
                // required
                />
                <label className="text-dark mt-3">Message</label>
                <textarea
                  rows="4"
                  value={message}
                  name="message"
                  onChange={handleInputChange}
                  className={`controls w-100 form-control mt-2 fromes form-control__input f-12 ${messageError ? "is-invalid__alert" : ""
                    }`}
                  placeholder={messageError ? messageError : "Write your message here"}
                // required
                />
                {/* <button
                  type="submit"
                  className={`btn btn-primary w-100 btn-md mt-3 ${
                    isLoading ? "send-cliper-pd" : ""
                  }`}
                  disabled={isLoading || !email || !name || !message}
                >
                  {isLoading ? (
                    <ClipLoader color="#fff" loading={isLoading} size={20} />
                  ) : (
                    "Send"
                  )}
                </button> */}
                <button
                  type="submit"
                  className={`btn btn-primary w-100 btn-md mt-3 ${isLoading ? "send-cliper-pd" : ""
                    }`}
                  disabled={isLoading || !name || !email || !message}
                >
                  {isLoading ? (
                    <ClipLoader color="#fff" loading={isLoading} size={20} />
                  ) : (
                    "Send"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
