import { faClose, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import {
  CustomMenuProp,
  getFileName,
  getStatusColor,
  getTimeAgo,
  handleSpaces,
  renderFileIcon,
  shortenFileName,
} from "../../../../Utils/helpers";
import unassign from "../../../../assets/images/UnassignedUserImage.svg";
import hg from "../../../../assets/images/hg-priotity.svg";
import low from "../../../../assets/images/low-priotity.svg";
import md from "../../../../assets/images/md-priotity.svg";
import none from "../../../../assets/images/none-priority.svg";
import "../../../../assets/scss/Modal.scss";
import "../../../../assets/scss/ViewTask.scss";
import AutocompleteLabels from "../../../../componets/comman/AutoCompleteLables";
import {
  closeLightBox,
  launchLightBox,
  selectTempFiles,
} from "../../../../componets/comman/CommonFunction/TaskFunctions";
import CustomTextEditor from "../../../../componets/comman/CustomTextEditor";
import FileViewBox from "../../../../componets/comman/Projects/FileLightBox";
import CustomUserAutoCompleteController from "../../../../componets/comman/Task/CustomUserAutoCompleteController";
import { api } from "../../../../customAxios";
function TaskModal(props) {
  const {
    isOpenTaskModelWindow,
    setIsOpenTaskModelWindow,
    isSubTask,
    isProgress,
    projectId,
    taskListId,
    handleCreateTask,
    projectStatuses,
    isTaskType,
    bugDetail,
    bugId,
    statusId,
    activeSprint,
    taskId,
    projectList,
    isTaskSprint,
    sprintId,
    isStory,
    setProgress,
  } = props;
  const [projectUser, setProjectUser] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const { id } = useParams();
  const [startDate, setStartDate] = useState();
  const [due_date, setDueDate] = useState();
  const [projectLabels, setProjectLabels] = useState([]);
  const [task_description, setTask_description] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("None");
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [openLightBox, setOpenLightBox] = useState(false);
  const [lightBoxImages, setLightBoxImages] = useState([]);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Title of work is required")
      .max(128, "Exceeded maximum character length of 128"),
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  useEffect(() => {
    if (isOpenTaskModelWindow) {
      setValue("assignees", 0);
      const url = !isSubTask ? `/project/${id}` : `/project/${projectId}`;
      api.get(url + "/labels").then((response) => {
        setProjectLabels(response.data);
      });
      reset();
      setStartDate(new Date());
      api.get(`/project/${projectId}/all-users`).then((res) => {
        setProjectUser(res.data);
      });
    } else {
      setDueDate("");
      setStartDate("");
      setSelectedLabels([]);
      setIsLoading(false);
      setTask_description("");
    }
    if (bugId) {
      setValue("name", bugDetail.bug_name);
      setValue("priority", bugDetail.bug_priority);
      setDueDate(bugDetail.bug_due_date);
    }
    if (!isOpenTaskModelWindow) {
      setFileList([]);
      setStartDate("");
      setIsLoading(false);
    }
  }, [isOpenTaskModelWindow]);
  const handleFileChange = (event) => {
    selectTempFiles(event, setProgress, setFileList);
  };
  const handleOpenLightBox = (file) => {
    launchLightBox(file, fileList, setOpenLightBox, setLightBoxImages);
  };
  const handleCloseLightBox = () => {
    closeLightBox(setLightBoxImages, setOpenLightBox);
  };
  const handleGetFile = (file) => {
    setFileList((prevFileList) => [...prevFileList, file]);
  };
  const handleDeselect = (file) => {
    const updatedFileList = fileList.filter(
      (selectedFile) => selectedFile !== file
    );
    setFileList(updatedFileList);
  };
  const handleClose = () => {
    setIsOpenTaskModelWindow(false);
    setSelectedPriority("None");
    setIsLoading(false);
    setFileList([]);
    setIsLoading(false);
    setStartDate("");
    reset();
  };
  const handleGetEditorState = (currentEditorState) => {
    setTask_description((prevValues) => ({
      ...prevValues,
      task_description: currentEditorState,
    }));
  };

  const handleDueDateChange = (date) => {
    setDueDate(date);
  };
  const handleStartDate = (date) => {
    setStartDate(date);
  };
  const handleSelectedLabelsChange = (newSelectedLabels) => {
    setSelectedLabels(newSelectedLabels);
  };
  const onSubmit = async (data) => {
    setIsLoading(true);
    const _dueDate = due_date ? moment(due_date).format("YYYY-MM-DD") : "";
    const _startDate = startDate ? moment(startDate).format("YYYY-MM-DD") : "";

    const formData = new FormData();
    if (isSubTask) {
      if (taskListId) {
        formData.append("project_id", projectId);
        formData.append("task_list", taskListId);
      } else if (activeSprint) {
        formData.append(`sprint_id`, activeSprint.id);
        formData.append("project_id", projectId);
      } else if (projectId && taskId) {
        formData.append("project_id", projectId);
        formData.append("parent_id", taskId);
      }
      if (projectId) {
        formData.append("project_id", projectId);
      }
      if (sprintId) {
        formData.append("sprint_id", sprintId);
      }
    } else if (isProgress) {
      formData.append("sprint_id", activeSprint?.id);
      formData.append("project_id", projectId);
    } else {
      formData.append("task_list", data.task_list);
      formData.append("project_id", projectId);
    }
    formData.append(
      "desc",
      task_description.task_description == undefined
        ? ""
        : task_description.task_description
    );
    formData.append("name", data.name);
    formData.append("due_date", _dueDate);
    formData.append("start_date", _startDate);
    formData.append("active", "A");
    formData.append("status_id", data.status_id);
    if (data.story_point > 0) {
      formData.append("story_point", data.story_point);
    }
    if (isStory) {
      formData.append("type", "E");
    } else {
      if (isTaskType) {
        formData.append("type", "B");
      } else {
        formData.append("type", "T");
      }
    }
    if (data.priority !== "None") {
      formData.append("priority", data.priority);
    }
    fileList.forEach((file, index) => {
      formData.append(`file_id[${index}]`, file.id);
    });
    const assigneesArray = data.assignees[0]?.value == 0 ? [] : [data.assignees[0].value];
    formData.append(`assignees`, JSON.stringify(assigneesArray));
    const followersArray = Array.isArray(data.followers)
      ? data.followers
      : [data.followers];
    formData.append(`followers`, JSON.stringify(followersArray.map((follower) => follower.value)));
    formData.append(`labels`, JSON.stringify(selectedLabels));
    await handleCreateTask(formData, data.task_list, setIsOpenTaskModelWindow);
    reset();
    setSelectedPriority("None");
  };

  const handlePriorityChange = (e) => {
    setSelectedPriority(e.target.value);
  };
  const handleStatus = (e) => {
    setSelectedStatus(e.target.value);
  };

  const UnassignedUserObject = {
    id: 0,
    name: "Unassigned",
    image: unassign,
    profile_color: "#707070",
  };
  const assigneeUsers = [UnassignedUserObject, ...(projectUser ?? [])];
  useEffect(() => {
    setValue(
      "assignees",
      [{ ...UnassignedUserObject, value: UnassignedUserObject.id }]
    );
  }, [])
  return (
    <>
      <Modal
        size="lg"
        show={isOpenTaskModelWindow}
        // onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="pt-5 pl-5 add-member_modal"
      >
        <div className="container">
          <div className="main-modal">
            <Modal.Header className="project-modal_header">
              <Modal.Title className="d-flex align-items-center">
                <div className="me-2">
                  <div
                    className={`task_modal_icon ${isSubTask && !isTaskType
                      ? "task-done-yellow"
                      : isTaskType
                        ? "bug"
                        : isStory
                          ? "userStory"
                          : "task-done-blue"
                      }`}
                  ></div>
                </div>
                {isStory
                  ? "Add User Story "
                  : isTaskType
                    ? "Add New Bug"
                    : !isSubTask
                      ? "Add New Task"
                      : "Add New Sub Task"}
              </Modal.Title>
              <FontAwesomeIcon icon={faClose} onClick={handleClose} />
            </Modal.Header>
            <Modal.Body className="project-modal_body">
              <div className="fields">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    {!isSubTask && !isProgress && (
                      <div className="col-md-12">
                        <div className="project-name border-black">
                          <h5 className="text-dark">Task Lists</h5>
                          <Select
                            className="mb-2 mt-1 w-100 inputcontrasts"
                            value={taskListId}
                            defaultValue={projectList && projectList[0]?.id}
                            {...register("task_list")}
                          >
                            {projectList &&
                              projectList.map((_taskList) => (
                                <MenuItem
                                  className="text-capitalize"
                                  value={_taskList?.id}
                                >
                                  {_taskList?.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </div>
                      </div>
                    )}
                    <div className="col-md-12 border-black">
                      <h5 className="text-dark mt-6px">
                        {isStory
                          ? "User Story Title"
                          : isTaskType
                            ? "Bug Title"
                            : "Task Title"}
                      </h5>
                      <input
                        type="text"
                        name="name"
                        placeholder={
                          isStory
                            ? "User story title"
                            : isTaskType
                              ? "Bug title"
                              : "Task title"
                        }
                        {...register("name")}
                        className={`form-control form-control__input mt-5px ${errors.name ? "is-invalid_boder" : ""
                          }`}
                        onKeyDown={handleKeyPress}
                        onKeyPress={handleSpaces}
                      />

                      <span className="danger-color error-msg">
                        {errors.name && errors.name.message}
                      </span>
                    </div>
                    <div className="col-md-12 ">
                      <h5 className="text-dark">Description</h5>
                      <div className="tab-content" id="nav-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="nav-home"
                          role="tabpanel"
                          aria-labelledby="nav-home-tab"
                        >
                          <div className="edit__wiki__box_2 mb-3 mt-2 border-black editormodalwindow">
                            <CustomTextEditor
                              handleGetEditorState={handleGetEditorState}
                              projectUser={projectUser}
                              isUploadTempFile={true}
                              handleGetFile={handleGetFile}
                              setProgress={setProgress}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="attachment">
                        <label
                          htmlFor="fileInput"
                          className="text-primary cursor_pointer "
                        >
                          + Add Files
                          <input
                            id="fileInput"
                            type="file"
                            name="file"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                            ref={fileInputRef}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="file_row mt-3">
                      {fileList.map((file, index) => (
                        <div className="file_divs mb-3 project-files-div">
                          <div
                            className="file_main_section project-files"
                            key={index + 1}
                          >
                            <div
                              className="mic-vid-icon add_cursor"
                              style={{ marginTop: "10px !important" }}
                            >
                              <div
                                className="imgs"
                                onClick={() => handleOpenLightBox(file)}
                              >
                                {renderFileIcon(file, true)}
                              </div>
                            </div>

                            <div className="file_main_section_left">
                              <p className="m-b-10 add_cursor">
                                {shortenFileName(getFileName(file?.name))}
                              </p>
                              <div className="d-flex justify-content-between file-bottom">
                                <div className="d-flex">
                                  <p className="time">
                                    {" "}
                                    {getTimeAgo(file.created_at)}{" "}
                                  </p>
                                </div>
                                <div className="dropdown_option">
                                  <Dropdown>
                                    <div className="option-icon">
                                      <Dropdown.Toggle
                                        id="dropdown-basic"
                                        className="button_style"
                                      >
                                        <FontAwesomeIcon icon={faEllipsisV} />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() => handleDeselect(file)}
                                        >
                                          Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </div>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-md-12">
                      <div className="project-name task-follower mt-1 border-black">
                        <h5 className="text-dark">Assigned To</h5>
                        <CustomUserAutoCompleteController fieldName={"assignees"} allUsers={assigneeUsers} control={control} placeholderText={"Select Assignee"} isSingle={true} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="project-name mt-2 border-black">
                        <h5 className="text-dark">Status</h5>
                        <Select
                          className={`mb-2 mt-5px w-100 inputcontrasts inputSelect`}
                          style={{
                            color: getStatusColor(
                              selectedStatus
                                ? selectedStatus
                                : statusId
                                  ? statusId
                                  : projectStatuses &&
                                  projectStatuses.length > 0 &&
                                  projectStatuses[0]?.id,
                              projectStatuses
                            )
                          }}
                          inputProps={{ "aria-label": "Without label" }}
                          {...register("status_id")}
                          defaultValue={
                            statusId
                              ? statusId
                              : projectStatuses && projectStatuses.length > 0
                                ? projectStatuses[0]?.id
                                : ""
                          }
                          onChange={handleStatus}
                        >
                          {projectStatuses &&
                            projectStatuses.map((status) => (
                              <MenuItem
                                style={{ color: status?.color }}
                                className="create-task-status"
                                value={status.id}
                              >
                                {status.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="project-name mt-2 border-black">
                        <h5 className="text-dark">Priority</h5>
                        <Select
                          className={`mb-2 mt-5px w-100 inputcontrasts inputSelect ${selectedPriority === "None"
                            ? "color-none_priority"
                            : selectedPriority === "Low"
                              ? "color-low_priority"
                              : selectedPriority === "Medium"
                                ? "color-md_priority"
                                : selectedPriority === "High"
                                  ? "color-hg_priority"
                                  : ""
                            }`}
                          {...register("priority")}
                          defaultValue="None"
                          onChange={handlePriorityChange}
                        >
                          <MenuItem
                            value="None"
                            className="create-task-priority color-none_priority"
                          >
                            <img src={none} alt="" /> &nbsp; None
                          </MenuItem>
                          <MenuItem
                            value="Low"
                            className="create-task-priority color-low_priority"
                          >
                            <img src={low} alt="" /> &nbsp; Low
                          </MenuItem>
                          <MenuItem
                            value="Medium"
                            className="create-task-priority color-md_priority"
                          >
                            <img src={md} alt="" /> &nbsp; Medium
                          </MenuItem>
                          <MenuItem
                            value="High"
                            className="create-task-priority color-hg_priority"
                          >
                            <img src={hg} alt="" /> &nbsp; High
                          </MenuItem>
                        </Select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h5 className="text-dark mt-2">Start Date</h5>
                      <div className="w-100 border-black">
                        <DatePicker
                          placeholderText="Select Start Date"
                          selected={startDate}
                          onChange={handleStartDate}
                          dateFormat="MMM d"
                          wrapperClassName="form-control form-control__input full-width p-0 mt-5px"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h5 className="text-dark mt-2">Due Date</h5>
                      <div className="w-100 border-black">
                        <DatePicker
                          placeholderText="Select Due Date"
                          selected={due_date}
                          onChange={handleDueDateChange}
                          dateFormat="MMM d"
                          wrapperClassName="form-control form-control__input full-width p-0 mt-5px"
                          minDate={startDate}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 border-black label_create_task task-labels modal-label">
                      <h5 className="text-dark">Labels</h5>
                      <AutocompleteLabels
                        borderNone={true}
                        className="inputcontrasts"
                        selectedLabels={selectedLabels}
                        onSelectedLabelsChange={handleSelectedLabelsChange}
                        projectLabels={projectLabels.map((label) => label.name)}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="project-name task-follower border-black">
                        <h5 className="text-dark mb_5">Followers</h5>
                        <CustomUserAutoCompleteController fieldName={"followers"} allUsers={projectUser} control={control} placeholderText={"Select Followers"} />
                      </div >
                    </div >
                    <div className="col-md-6">
                      <div className="project-name border-black">
                        <h5 className="text-dark mt-4px">Story Points</h5>
                        <Select
                          className={`mb-2 mt-6px w-100 inputcontrasts inputSelect`}
                          {...register("story_point")}
                          defaultValue={1}
                          name="story_point"
                          MenuProps={CustomMenuProp()}
                        >
                          {[...Array(10)].map((_, index) => (
                            <MenuItem key={index + 1} value={index + 1}>
                              {index + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div >
                  <div className="actions">
                    <button
                      className="btn btn-cancel btn-sm"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      className={`btn btn-primary btn-sm ${isLoading ? "pd-cre-task-load" : ""
                        }`}
                      type="submit"
                      disabled={!isValid || isLoading}
                    >
                      {isLoading ? (
                        <ClipLoader
                          color="#fff"
                          loading={isLoading}
                          size={20}
                        />
                      ) : (
                        "Create"
                      )}
                    </button>
                  </div>
                </form >
              </div >
            </Modal.Body >
          </div >
        </div >
        <FileViewBox
          images={lightBoxImages}
          isOpen={openLightBox}
          onClose={handleCloseLightBox}
        />
      </Modal >
    </>
  );
}

export default TaskModal;
