import React from "react";
import FileBoxLoader from "./FileBoxLoader";

const ViewFileDrawerLoader = () => {
  return (
    <>
      <div className="container-fluid viewFile_container">
        <div className="row ">
          <div className="col-md-12">
            <FileBoxLoader isViewFile={true} />
            {[...Array(3)].map((_, i) => (
              <div className="row mt-3" key={i}>
                <div className="col-md-2">
                  <h5
                    className={`br_6 ${i === 1 ? "w-75" : " loading_heading_long"
                      } h_26 shimmerBG`}
                  >.</h5>
                </div>
                <div className="col-md-10">
                  <h5
                    className={`br_6 ${i === 2 ? "loading_heading_long" : "w-50"
                      } h_26 shimmerBG`}
                  >.</h5>
                </div>
              </div>
            ))}
            <div className="row ">
              <div className="col-md-8">
                <h5 className="br_6 loading_heading_long h_26 shimmerBG mt-5">.</h5>
                <div className="shimmerBG  h-150 br_6 mt-3"></div>
                <div className="d-flex justify-content-between">
                  {[...Array(2)].map((_, i) => (
                    <div className="shimmerBG btn-load-normal mt-3"></div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewFileDrawerLoader;
