import axios from "axios";
import moment from "moment";
import docIcon from "../assets/images/file-extenshion/doc.svg";
import jsonIcon from "../assets/images/file-extenshion/josn.svg";
import mp3Icon from "../assets/images/file-extenshion/mp3.svg";
import mp4Icon from "../assets/images/file-extenshion/mp4.svg";
import noNameIcon from "../assets/images/file-extenshion/no-Name.svg";
import pdfIcon from "../assets/images/file-extenshion/pdf.svg";
import svgIcon from "../assets/images/file-extenshion/svg.svg";
import zipIcon from "../assets/images/file-extenshion/zip.svg";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";

export const formateDate = (date) => {
  const formateDate = new Date(date);
  const option = { month: "short", day: "numeric" };
  return formateDate.toLocaleDateString("en-US", option);
};
export const formatName = (user_name) => {
  if (!user_name || typeof user_name !== "string") {
    return null;
  }
  const [firstName, lastName] = user_name?.split(" ");
  const lastNameInitial = lastName ? `${lastName[0]}.` : "";
  return `${firstName} ${lastNameInitial}`;
};
export const fullDateFormate = (date) => {
  const parsedDate = new Date(date);
  if (isNaN(parsedDate)) {
    return "Invalid Date";
  }

  const options = { month: "short", day: "numeric", year: "numeric" };
  return parsedDate.toLocaleDateString("en-US", options);
};
export const getCreatedTime = (date) => {
  const actualTime = moment(date).format("h:mm A");
  return actualTime;
};
export const getTimeAgo = (date) => {
  const momentDate = moment.utc(date);
  const now = moment.utc();
  const diffInSeconds = now.diff(momentDate, "seconds");

  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    min: 60,
    sec: 1,
  };

  for (const [unit, seconds] of Object.entries(intervals)) {
    const diff = Math.floor(diffInSeconds / seconds);

    if (diff >= 1) {
      return `${diff} ${unit}${diff > 1 ? "s" : ""} ago`;
    }
  }

  return "just now";
};
export const handleSpaces = (event) => {
  let e = window.event || event;

  let key = e.keyCode;

  //space pressed

  if (key === 32) {
    if (event.target.value < 1) event.preventDefault();
  }
};
export const hexToRGBA = (hexColor) => {
  if (hexColor && typeof hexColor === "string") {
    hexColor = hexColor.replace("#", "");
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);
    return { r, g, b };
  }
  return { r: 0, g: 0, b: 0 };
};
export const formatSprintCount = (count) => {
  if (count < 10 && count > 1) {
    return `${count}`;
  } else if (count === 1) {
    return "1";
  } else if (count >= 10) {
    return `${count}`;
  } else {
    return "0";
  }
};
export const formatItemName = (count, story) => {
  if (count < 10 && count > 1) {
    return `${story ? "ies" : "s"}`;
  } else if (count === 1) {
    return `${story ? "y" : ""}`;
  } else if (count >= 10) {
    return `${story ? "ies" : "s"}`;
  } else {
    return `${story ? "y" : ""}`;
  }
};
export const formatCountWithName = (count, type) => {
  if (count < 10 && count > 1) {
    return `${count} ${type === "sprint"
      ? "Sprints"
      : type === "bug"
        ? "Bugs"
        : type === "points"
          ? "Pts"
          : type === "story"
            ? "Stories"
            : "Tasks"
      }`;
  } else if (count === 1) {
    return `1 ${type === "sprint"
      ? "Sprint"
      : type === "bug"
        ? "Bug"
        : type === "points"
          ? "Pt"
          : type === "story"
            ? "Story"
            : "Task"
      }`;
  } else if (count >= 10) {
    return `${count} ${type === "sprint"
      ? "Sprints"
      : type === "bug"
        ? "Bugs"
        : type === "points"
          ? "Pts"
          : type === "story"
            ? "Stories"
            : "Tasks"
      }`;
  } else {
    return `0 ${type === "sprint"
      ? "Sprint"
      : type === "bug"
        ? "Bug"
        : type === "points"
          ? "Pt"
          : type === "story"
            ? "Story"
            : "Task"
      }`;
  }
};
export const findPercentage = (total, complete) => {
  if (
    typeof total !== "number" ||
    typeof complete !== "number" ||
    isNaN(total) ||
    isNaN(complete)
  ) {
    return 0;
  }
  if (total === 0) {
    return 0;
  }
  if (complete < 0) {
    return 0;
  }

  const percent = Math.round((complete / total) * 100);
  return percent;
};
export const totalTaskSum = (...counts) => {
  return counts.reduce((total, count) => total + (count || 0), 0);
};
export const handleDownloadDocument = async (file, setProgress) => {
  if (setProgress) {
    setProgress(30);
  }
  try {
    const url = file.name;
    const response = await axios.get(url, {
      responseType: "blob",
    });
    if (setProgress) {
      setProgress(60);
    }
    const blobUrl = window.URL.createObjectURL(response.data);
    const filename = url.substring(url.lastIndexOf("/") + 1);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = blobUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(blobUrl);
    document.body.removeChild(a);
    if (setProgress) {
      setProgress(100);
    }
  } catch (error) {
    console.error("Error downloading the file:", error);
    if (setProgress) {
      setProgress(100);
    }
  }
};
export const handleCopyText = (text, textType) => {
  navigator.clipboard.writeText(text);
  toast.info(`${textType} Copied!`, { position: "bottom-right" });
};
export const handleCopyFilePath = (file) => {
  const base64Encoded = btoa(file?.name);
  const shareLink = `${window.location.origin}/share/${base64Encoded}`;
  copy(shareLink);
  toast.info("File Link Copied!", { position: "bottom-right" });
};
export const getFileName = (input) => {
  try {
    const url = new URL(input);
    const path = url.pathname;
    const fileName = path.substring(path.lastIndexOf("/") + 1);
    return fileName;
  } catch (error) {
    return input;
  }
};

export const shortenFileName = (fileName, maxLength = 20) => {
  if (fileName?.length <= maxLength) {
    return fileName;
  }

  const extension = fileName?.substring(fileName.lastIndexOf(".") + 1);
  const start = fileName?.substring(0, (maxLength - extension.length - 2) / 2);
  const end = fileName?.substring(
    fileName.length - (maxLength - extension.length - 0) / 2
  );

  return `${start}...${end}`;
};
export const renderFileIcon = (file, isProjectFile) => {
  if (!file) return null;

  const fileName = file.name;
  if (fileName.endsWith(".pdf")) {
    return <img src={pdfIcon} alt="PDF file" className={`w-40px h-100`} />;
  } else if (fileName.endsWith(".json")) {
    return <img src={pdfIcon} alt="json file" className={`w-40px h-100`} />;
  } else if (fileName.endsWith(".zip")) {
    return <img src={zipIcon} alt="zip file" className={`w-40px h-100`} />;
  } else if (/\.(doc|docs|docx)$/i.test(fileName)) {
    return <img src={docIcon} alt="doc file" className={`w-40px h-100`} />;
  } else if (fileName.endsWith(".mp4")) {
    return <img src={mp4Icon} alt="mp4 file" className={`w-40px h-100`} />;
  } else if (fileName.endsWith(".mp3")) {
    return <img src={mp3Icon} alt="mp3 file" className={`w-40px h-100`} />;
  } else if (fileName.endsWith(".json")) {
    return <img src={jsonIcon} alt="JSON file" className={`w-40px h-100`} />;
  } else if (fileName.endsWith(".svg")) {
    return <img src={svgIcon} alt="Svg file" className={`w-40px h-100`} />;
  } else if (/\.(png|jpg|jpeg|gif)$/.test(fileName)) {
    return <img src={fileName} alt="" className={`w-40px h-52`} />;
  } else {
    return (
      <img src={noNameIcon} alt="Other file type" className={`w-40px h-52`} />
    );
  }
};
export const htmlTagRegex = (text) => {
  const isExist = /<[a-z][\s\S]*>/i.test(text);
  return isExist;
};
export const scrollToTop = () => {
  window.scrollTo(0, 0);
};
export const scrollToSectionTop = (sectionId) => {
  const element = document.getElementById(sectionId);
  const headerOffset = 50;
  const additionalOffset = 30;

  if (element) {
    const elementPosition =
      element.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = elementPosition - headerOffset - additionalOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

export const formatAmountToCurrency = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};
export const CustomMenuProp = (onlyHeight) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const maxHeight = onlyHeight
    ? onlyHeight
    : ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: maxHeight,
        width: onlyHeight ? " " : 250,
      },
    },
  };
  return MenuProps;
};
export const getStatusName = (statusId, statusList) => {
  const status = statusList?.find((status) => status.id == statusId);
  return status?.name ? status.name : "--------";
};
export const getStatusColor = (statusId, statusList) => {
  const status = statusList?.find((status) => status.id == statusId);
  return status?.color ? status.color : "#7a7a7a";
};
export const getStatusColorStyle = (color) => {
  if (color) {
    return {
      color: color,
      backgroundColor: `rgba(${hexToRGBA(color).r}, ${hexToRGBA(color).g}, ${hexToRGBA(color).b
        }, 0.1)`,

    };
  } else {
    return {};
  }
};
export const camelCaseToNormalCase = (camelCase) => {
  return camelCase
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase());
};
const getUserProperty = (allUsers, selectedUser, property) => {
  return allUsers?.find((user) => user.id === selectedUser)?.[property] || "";
};
export const getUserName = (allUsers, selectedUser) => getUserProperty(allUsers, selectedUser, 'name');
export const getUserImage = (allUsers, selectedUser) => getUserProperty(allUsers, selectedUser, 'image');
export const getUserProfileColor = (allUsers, selectedUser) => getUserProperty(allUsers, selectedUser, 'profile_color');
export const testCaseStatuses = [
  { id: 1, name: "Failed", color: "#F0142F" },
  { id: 2, name: "Passed", color: "#28A745" },
  { id: 3, name: "Retest", color: "#FFAB00" },
  { id: 4, name: "Blocked", color: "#707070" },
]
export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};