import React from "react";
function FileBoxLoader(props) {
  const { isFiles, isViewFile } = props;
  const FileBox = ({ key: i }) => {
    return (
      <div
        className={`col-md-${isViewFile ? "4" : "3"} mb-3 file_box_col`}
        key={i + 1}
      >
        <div className={`file_box ${isFiles ? "d-flex p-10" : ""}`}>
          <div
            className={`file_icon   shimmerBG  ${!isFiles ? "file_thumbnail br_10 w-100" : "file_icon_load br_4"
              }`}
          ></div>
          <div className={`file_details ${!isFiles ? "p-10 w_unset" : "ms-1"}`}>
            <p className="mb-0 add_cursor file_name file_name_load shimmerBG br_4"></p>
            <div className="d-flex justify-content-between mt-3">
              {isFiles && (
                <>
                  <div className="file_action_load shimmerBG"></div>
                  <div className="file_action_load shimmerBG"></div>
                </>
              )}
              <p className="file_time_ago file_time_load shimmerBG mb-0 br_4"></p>
              {!isViewFile && (
                <div className="file_action_load shimmerBG br_4"></div>
              )}
              {!isFiles && (
                <>
                  <div className="file_action_load shimmerBG br_4"></div>
                  <div className="file_action_load shimmerBG br_4"></div>
                  <div className="file_action_load shimmerBG br_4"></div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="row mt-3 project_files">
        {isFiles && (
          <div className="col-12 mb-3">
            <h4 className="br_6 loading_heading_long h_26 shimmerBG ">.</h4>
          </div>
        )}
        {isViewFile ? (
          <FileBox />
        ) : (
          [...Array(24)].map((e, i) => (
            <>
              {isFiles && i % 8 === 0 && i !== 0 && (
                <div className="col-12 mb-3" key={`heading-${i / 8}`}>
                  <h4 className="br_6 loading_heading_long h_26 shimmerBG">.</h4>
                </div>
              )}
              <FileBox key={i} />
            </>
          ))
        )}
      </div>
    </>
  );
}

export default FileBoxLoader;
