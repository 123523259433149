import { yupResolver } from "@hookform/resolvers/yup";
import { api } from "../../../customAxios";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ClipLoader } from "react-spinners";

function Forget() {
  const schema = yup.object().shape({
    email: yup.string().required("Email is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onClick",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const saveButtonRef = useRef(null);

  const handleforgotpassword = (data) => {
    setIsLoading(true);
    api
      .post("/auth/forget-password", data)
      .then((res) => {
        toast.success(res?.data?.message);
        navigate("/signin");
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error("Email is invalid.");
        setIsLoading(false);
      });
  };

  return (
    <div className="verify_email_box">
      <div className="login__card-2">
        <h4 className="text-center mb-3">Forgot Your Password</h4>
        <p className="text-center custom-font-size-14">
          Enter your email address and we'll send you a link to reset your
          password.
        </p>
        <form className="form" onSubmit={handleSubmit(handleforgotpassword)}>
          <div className="pb-3">
            <label className="form-label form-label__name mt-3 ">Email</label>
            <input
              type="email"
              name="email"
              className={`form-control form-control__input f-12 w-100  ${errors.email ? "is-invalid_boder" : ""
                }`}
              placeholder="Enter email address"
              {...register("email")}
            />
            <span className="danger-color error-msg">
              {" "}
              <span>{errors.email && errors.email.message}</span>
            </span>
          </div>
          <button
            ref={saveButtonRef}
            type="submit"
            disabled={!isDirty || !isValid || isLoading}
            className={`btn btn-primary all-btn-padding mt-3 pt-3 pb-3 w-100 ${isLoading ? "reset-cliper-pd" : ""
              }`}
          >
            {isLoading ? <ClipLoader color="#fff" size={20} /> : "Reset"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Forget;
