import {
  faChevronDown,
  faClose,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { createProject, updateProject } from "../../../Redux/Slice/Projects";
import {
  getFileName,
  handleSpaces,
  renderFileIcon,
} from "../../../Utils/helpers";
import { api } from "../../../customAxios";
import {
  closeLightBox,
  launchLightBox,
  selectTempFiles,
} from "../CommonFunction/TaskFunctions";
import CustomTextEditor from "../CustomTextEditor";
import FileViewBox from "./FileLightBox";
import { Link, useLocation, useNavigate } from "react-router-dom";

function ProjectModalWindow(props) {
  const {
    open,
    setOpen,
    project,
    isEditProject,
    setIsEditProject,
    projectUser,
    refreshProject,
    setProgress,
    isArchiveProject,
  } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedEditIndex, setSelectedEditIndex] = useState(null);
  const [desc, setDesc] = useState("");
  const [addMemberError, setAddMemberError] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [teamMember, setTeamMember] = useState([]);
  const userSelectRef = useRef(null);
  const roleSelectRef = useRef(null);
  const users = useSelector((state) => state.project.users || []);
  const roles = useSelector((state) => state.project.roles || []);
  const storedUserData = localStorage.getItem("user");
  const JsonUser = JSON.parse(storedUserData);
  const loggedUserId = JsonUser?.id;
  const [isDisabled, setIsDisabled] = useState(false);
  const [isEditDescription, setIsEditDescription] = useState(false);
  const [isAddUpdate, setIsAddUpdate] = useState(false);
  const [openLightBox, setOpenLightBox] = useState(false);
  const [lightBoxImages, setLightBoxImages] = useState([]);
  useEffect(() => {
    if (project?.id) {
      api.get(`/projects/${project?.id}/files`).then((resp) => {
        const filteredFiles = resp.data.filter(
          (file) => file.fileable_type === "App\\Models\\Project"
        );
        setFileList(filteredFiles);
      });
    }
  }, [project?.id]);
  const sprintValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Project name is required")
      .max(128, "Exceeded maximum character length of 128"),
  });

  const { formState, register, handleSubmit, watch, setValue, reset } = useForm(
    {
      resolver: yupResolver(sprintValidationSchema),
      mode: "onChange",
      defaultValues: {
        name: project?.name || "",
        desc: project?.description || "",
      },
    }
  );
  const { errors } = formState;
  const watchFields = watch(["name"]);

  useEffect(() => {
    if (!open) {
      reset();
      setValue("name", "");
      setValue("desc", "");
      setDesc("");
      setTeamMember([]);
      setFileList([]);
      setAddMemberError([]);
      setIsDisabled(false);
      setIsAddUpdate(false);
      if (setIsEditProject) {
        setIsEditProject(false);
      }
      setOpen(false);
    }
  }, [open]);

  useEffect(() => {
    setValue("name", project?.name || "");
    setValue("desc", project?.description || "");
    setIsEditDescription(true);
    setIsLoading(false);
    setIsDisabled(false);
  }, [project, setValue]);

  useEffect(() => {
    if (project) {
      setTeamMember(project.users);
    } else {
      setIsDisabled(false);
    }
  }, [project]);

  const handleGetEditorState = (currentEditorState) => {
    setValue("desc", currentEditorState);
    setDesc(currentEditorState);
  };
  const handleUserChange = (e) => {
    const selectedUser = users.find(
      (user) => user.id === parseInt(e.target.value)
    );
    setSelectedUser(selectedUser);
  };
  const handleRoleChange = (e) => {
    const selectedRole = roles.find(
      (role) => role.id === parseInt(e.target.value)
    );
    setSelectedRole(selectedRole);
  };

  const handleAddButtonClick = () => {
    if (isEditProject) {
      addUsers(selectedUser, selectedRole);
    } else {
      handleAdd();
    }
  };

  const addUsers = (user, role) => {
    const formData = new FormData();
    formData.append("user_id", user?.id);
    formData.append("role_id", role?.id);
    formData.append("project_id", project?.id);

    api
      .post("/project/add-user-to-project", formData)
      .then((res) => {
        setTeamMember([
          ...teamMember,
          {
            user_id: user.id,
            user_name: user.name,
            role_id: role.id,
            role_name: role.name,
          },
        ]);
      })
      .catch((error) => {
        if (error.response.data.message) {
          const errorMessage = error.response.data.message;
          setAddMemberError([errorMessage]);
          setSelectedRole("");
        } else {
          setAddMemberError([error.response.data.errors]);
        }
      })
      .finally(() => {
        setTimeout(() => {
          setAddMemberError([]);
        }, 5000);
      });
  };

  const handleAdd = () => {
    if (!selectedUser) {
      setAddMemberError(["Please select a user."]);
      setTimeout(() => {
        setAddMemberError([]);
      }, 5000);
      return;
    }
    setTeamMember((prevTeamMember) => prevTeamMember || []);

    const isUserAlreadyAdded = teamMember?.some(
      (existingMember) => existingMember?.user_id === selectedUser?.id
    );
    if (isUserAlreadyAdded) {
      setAddMemberError(["User already exists in this project."]);
      setTimeout(() => {
        setAddMemberError([]);
      }, 5000);
    } else {
      setTeamMember((prevTeamMember) => [
        ...prevTeamMember,
        {
          user_id: selectedUser?.id,
          user_name: selectedUser?.name,
          role_id: selectedRole ? selectedRole.id : 1,
          role_name: selectedRole?.name || "Administrator",
        },
      ]);
      setAddMemberError([]);
      userSelectRef.current.value = "";
      roleSelectRef.current.value = "";
      setSelectedRole(roleSelectRef);
    }
  };

  const handleDeleteImage = (file) => {
    if (isEditProject) {
      if (file?.id) {
        api
          .delete(`/files/${file?.id}`)
          .then((res) => {
            toast.success(res.data.message);
            const updatedFileList = fileList.filter(
              (selectedFile) => selectedFile.id !== file?.id
            );
            setFileList(updatedFileList);
          })
          .catch((error) => {
            toast.error("Error deleting file");
          });
      } else {
        if (fileList.length > 0) {
          const updatedFileList = fileList.slice(1);
          setFileList(updatedFileList);
        }
      }
    } else {
      const updatedFileList = fileList.filter(
        (selectedFile) => selectedFile !== file
      );
      const updatedFiles = files.filter(
        (fileInFiles) => fileInFiles.name !== file.name
      );
      setFiles(updatedFiles);
      setFileList(updatedFileList);
    }
  };
  const handleFileChange = (event) => {
    selectTempFiles(event, setProgress, setFileList);
  };
  const handleOpenFile = (file) => {
    launchLightBox(file, fileList, setOpenLightBox, setLightBoxImages);
  };
  const handleCloseFile = () => {
    closeLightBox(setLightBoxImages, setOpenLightBox);
  };
  const handleRoleUpdateAndApiCall = async (userId, roleId, index) => {
    const handleRoleUpdateFunction = isEditProject
      ? handleRoleUpdateWithApi
      : handleRoleUpdateWithoutApi;
    handleRoleUpdateFunction(userId, roleId, index);
  };

  const handleRoleUpdateWithoutApi = (userId, roleId) => {
    setTeamMember((prevMembers) => {
      const updatedMembers = prevMembers.map((member) => {
        if (member.user_id === userId) {
          return {
            ...member,
            role_id: roleId,
            role_name: roles.find((role) => role.id === roleId)?.name,
          };
        }
        return member;
      });
      return updatedMembers;
    });
  };
  const handleRoleUpdateWithApi = async (userId, roleId) => {
    setTeamMember((prevMembers) => {
      const updatedMembers = [...prevMembers];
      updatedMembers[selectedEditIndex] = {
        ...updatedMembers[selectedEditIndex],
        role_id: roleId,
        role_name: roles.find((role) => role.id === roleId)?.name,
      };
      return updatedMembers;
    });

    try {
      await updateProjectUser(userId, roleId);
    } catch (error) {
      toast.error("Error updating project user");
    }

  };

  const updateProjectUser = async (userId, roleId) => {
    try {
      await api.post("/project/update-project-user", {
        user_id: userId,
        role_id: roleId,
        project_id: project?.id,
      });
    } catch (error) {
      toast.error("Error updating project user");
      throw error;
    }
  };

  function handleProjectOperation(data) {
    if (isEditProject) {
      handleProjectUpdate(data);
    } else {
      createProjectData(data);
    }
  }
  function createProjectData(data) {
    setIsDisabled(true);
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("enterprise_id", localStorage.getItem("enterpriceID"));
    const status = isArchiveProject ? "I" : "A";
    formData.append("status", status);
    formData.append("description", desc);

    fileList?.forEach((file, index) => {
      formData.append(`file_id[${index}]`, file.id);
    });

    teamMember?.forEach((member, index) => {
      formData.append(`users[${index}][user_id]`, member.user_id);
      formData.append(`users[${index}][role_id]`, member.role_id);
    });
    dispatch(createProject({ formData, isArchiveProject }))
      .then((action) => {
        if (action?.payload?.message) {
          toast.success(action?.payload?.message);
          localStorage.setItem(
            "permissions",
            JSON.stringify(action?.payload?.permissions)
          );
          setOpen(false);
          const project = {
            newProject: action.payload.project,
          };
          navigate(location.pathname, { state: { project } });
        } else {
          toast.error(action?.payload?.error);
        }
      })
      .finally(() => {
        setIsDisabled(false);
        setIsLoading(false);
      });
  }

  function handleProjectUpdate(data) {
    setIsDisabled(true);
    setIsLoading(true);
    const projectId = project?.id;
    const id = project?.id;

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.desc);
    formData.append("status", "A");
    formData.append("project_id", id);
    formData.append("enterprise_id", localStorage.getItem("enterpriceID"));
    const filteredFiles = fileList.filter((file) => !file?.fileable_type);
    filteredFiles.forEach((file, index) => {
      formData.append(`file_id[${index}]`, file?.id);
    });
    dispatch(
      updateProject({
        projectId,
        updatedData: formData,
      })
    )
      .then((action) => {
        if (action.payload && action.payload.status === 200) {
          toast.success("Project update successfully!");
          refreshProject(data, projectId, project.users);
          setIsAddUpdate(true);
        } else {
          toast.error(action.payload.data.error);
        }
        setOpen(false);
        reset();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const deleteUserMemberHandler = (userId, roleId, id, index) => {
    if (isEditProject) {
      const payload = {
        user_id: userId,
        role_id: roleId,
        project_id: project?.id,
      };
      api
        .post("/project/remove-project-member/", payload)
        .then((response) => {
          if (response.status === 200) {
            const updatedTeamMember = project?.users.filter(
              (member) => member.user_id !== userId || member.role_id !== roleId
            );
            setTeamMember(updatedTeamMember);
          }
        })
        .catch((error) => { });
    } else {
      const newSelectedMembers = [...teamMember];

      if (index >= 0 && index < newSelectedMembers.length) {
        newSelectedMembers.splice(index, 1);
        setTeamMember(newSelectedMembers);
      }
    }
  };
  const handleGetFile = (file) => {
    setFileList((prevFileList) => [...prevFileList, file]);
  };
  return (
    <div>
      <Modal
        size="lg"
        show={open}
        // onHide={() => {
        //   setOpen(false);
        // }}
        aria-labelledby="example-modal-sizes-title-lg"
        className="pt-5 create-project_modal add-member_modal"
      >
        <div className="container">
          <div className="main-modal">
            <Modal.Header className="project-modal_header">
              <Modal.Title>
                {isEditProject ? "Edit project" : "Create Project"}
              </Modal.Title>
              <FontAwesomeIcon
                icon={faClose}
                onClick={() => {
                  setOpen(false);
                }}
              />
            </Modal.Header>
            <Modal.Body className="project-modal_body">
              <form onSubmit={handleSubmit(handleProjectOperation)}>
                <div className="fields">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="project-name">
                        <h5 className="text-black">Project name</h5>
                        <input
                          type="text"
                          placeholder="Enter project name"
                          className={`form-control form-control__input ${errors.name ? "is-invalid_boder" : ""
                            }`}
                          name="name"
                          {...register("name")}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                            }
                          }}
                          onKeyPress={handleSpaces}
                        />
                        <span className="danger-color error-msg">
                          {errors.name && errors.name.message}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="description">
                        <h5>Description</h5>
                        <div className="edit__wiki__box_2 mb-3 mt-2 editormodalwindow projectmodalwindow">
                          <CustomTextEditor
                            handleGetEditorState={handleGetEditorState}
                            description={project?.description}
                            isEditDescription={isEditDescription}
                            placeholder={"Add Description"}
                            projectUser={projectUser}
                            isAddUpdate={isAddUpdate}
                            isUploadTempFile={true}
                            handleGetFile={handleGetFile}
                            setProgress={setProgress}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="attachment">
                        <label
                          htmlFor="fileInput"
                          className="text-primary cursor_pointer "
                        >
                          + Add Files
                          <input
                            id="fileInput"
                            type="file"
                            name="file"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                            ref={fileInputRef}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="file_row mt-3">
                      {fileList?.length > 0
                        ? fileList.map((file, index) => {
                          return (
                            <div className="file_divs mb-3" key={index}>
                              <div className="file_main_section file_padding_none">
                                {
                                  <div
                                    className="file_top_section"
                                    onClick={() => handleOpenFile(file)}
                                  >
                                    {renderFileIcon(file)}
                                  </div>
                                }
                                <FontAwesomeIcon
                                  icon={faClose}
                                  className="delete_file"
                                  onClick={() => handleDeleteImage(file)}
                                />
                                <div className="file_bottom_section">
                                  <span className="d-flex justify-content-between ">
                                    <p className="file_name mx-2 textEllipse">
                                      {getFileName(file?.name)}
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="team">
                  {isEditProject ? (
                    ""
                  ) : (
                    <>
                      <h4>Add Team</h4>
                      <div className="row team-row">
                        <div className="col-md-6">
                          <h5>Select Member</h5>
                          <select
                            className={`text-capitalize form-select form-select__select w-100`}
                            type="text"
                            ref={userSelectRef}
                            defaultValue={selectedUser}
                            onChange={handleUserChange}
                          >
                            <option key="blankKey" hidden value="">
                              {" "}
                              Select Member
                            </option>
                            {users &&
                              users.map((user) => {
                                const isUserDisabled = teamMember?.some(
                                  (tm) => tm.user_id === user.id
                                );
                                return (
                                  <option
                                    key={user.id}
                                    value={user.id.toString()}
                                    hidden={loggedUserId === user.id}
                                    disabled={isUserDisabled}
                                  >
                                    {user.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="col-md-4">
                          <h5 style={{ marginLeft: "8px" }}>Select Role</h5>
                          <select
                            className={`text-capitalize form-select form-select__select w-100 role-field`}
                            type="text"
                            ref={roleSelectRef}
                            defaultValue={selectedRole}
                            onChange={handleRoleChange}
                          >
                            <option value="" hidden>
                              Select Role
                            </option>
                            {roles?.map((role, index) => (
                              <option key={role.id} value={role.id.toString()}>
                                {role.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-2">
                          <Link
                            className="btn btn-primary btn-sm"
                            onClick={handleAddButtonClick}
                            disabled={isDisabled}
                          >
                            Add
                          </Link>
                        </div>
                        <div className="col-md-12">
                          {addMemberError.length > 0 && (
                            <div className="errors">
                              {addMemberError.map((error, index) => (
                                <p key={index}>
                                  {error === "The selected user id is invalid."
                                    ? "Please select a member."
                                    : error}
                                </p>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-18">
                        <div className="mb-2 bg-white table-bg bx-shadow-none">
                          <table className="table table-w">
                            <thead>
                              <tr className="b-none table_row f-16">
                                <th className="text-left first_th">
                                  <span className=" f-regular text-dark">
                                    Name
                                  </span>
                                </th>
                                <th className="text-center">
                                  <span className=" f-regular text-dark">
                                    Role
                                  </span>
                                </th>
                                <th className="text-end last_th">
                                  <span className=" f-regular text-dark">
                                    Action
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {teamMember?.map((member, index) => (
                                <tr key={index}>
                                  <td className="text-left">
                                    <p className="text-capitalize f-regular py-1 m-0 user_name">
                                      {member.user_name}
                                    </p>
                                  </td>
                                  <td
                                    className="modal__td text-center"
                                    style={{ position: "relative" }}
                                  >
                                    <div className="default">
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          id={`dropdown-basic-${index}`}
                                          className="items"
                                        >
                                          {member?.role_name
                                            ?.charAt(0)
                                            .toUpperCase() +
                                            member?.role_name?.slice(1)}
                                          <FontAwesomeIcon
                                            icon={faChevronDown}
                                          />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {roles?.map((role) => (
                                            <Dropdown.Item
                                              key={role.id}
                                              onClick={() =>
                                                handleRoleUpdateAndApiCall(
                                                  member.user_id,
                                                  role.id,
                                                  role.name,
                                                  index
                                                )
                                              }
                                            >
                                              {role?.name
                                                ?.charAt(0)
                                                .toUpperCase() +
                                                role?.name?.slice(1)}
                                            </Dropdown.Item>
                                          ))}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </td>
                                  <td className="text-end">
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      className="action gray action_name"
                                      onClick={() =>
                                        deleteUserMemberHandler(
                                          member.user_id,
                                          member.role_id,
                                          project?.id,
                                          index
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="actions">
                    <Link
                      className="btn btn-cancel btn-sm"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </Link>
                    <button
                      className={`btn btn-primary btn-sm ${isLoading && isEditProject
                        ? "pd-edit-pro-load"
                        : isLoading && !isEditProject
                          ? "pd-cre-pro-load"
                          : ""
                        }`}
                      type="submit"
                      disabled={!watchFields[0] || isDisabled || isLoading}
                    >
                      {isLoading ? (
                        <ClipLoader
                          color="#ffffff"
                          loading={isLoading}
                          size={20}
                        />
                      ) : isEditProject ? (
                        "Save Project"
                      ) : (
                        "Create"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </div>
        </div>
        <FileViewBox
          images={lightBoxImages}
          isOpen={openLightBox}
          onClose={handleCloseFile}
        />
      </Modal>
    </div>
  );
}

export default ProjectModalWindow;
