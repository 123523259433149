import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { editTask } from "../../../Redux/Slice/Task";
import AI from "../../../assets/images/ai-black.svg";
import { api } from "../../../customAxios";
import CustomTextEditor from "../CustomTextEditor";
import { Link } from "react-router-dom";
function AiModalWindow(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { state, setState, taskDetail, setTaskDetail } = props;
  const [data, setData] = useState();

  useEffect(() => {
    if (state.open) {
      setLoading(true);
      const parser = new DOMParser();
      const doc = parser.parseFromString(state.desc, "text/html");
      const imgs = doc.getElementsByTagName("img");
      const imgTagsArray = [];

      Array.from(imgs).forEach((img) => {
        imgTagsArray.push(img.outerHTML);
        img.parentNode.removeChild(img);
      });

      const cleanedDesc = doc.body.innerHTML;
      const imgTagsString = imgTagsArray.join("");
      const payload = {
        description: cleanedDesc,
        type: state.type,
      };

      api
        .post("/task-description-summary", payload)
        .then((res) => {
          const newDesc = `${res.data.description}${imgTagsString}`;
          setData(newDesc);
          setState((prev) => ({
            ...prev,
            newDesc: newDesc,
          }));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [state?.open]);

  const onSubmit = async () => {
    const taskId = taskDetail.task_id;
    var payload = {};
    payload.desc = state.newDesc;
    setLoading(true);

    dispatch(editTask({ taskId, payload }))
      .then((response) => {
        toast.success("Task description changed successfully!");
        setTaskDetail((detail) => ({
          ...detail,
          task_desc: state.newDesc,
        }));
        setState({});
        setData("");
      })
      .catch((error) => {
        toast.error("Oops! something went wrong please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setState({});
    setData("");
  };

  const handleGetEditorState = (text) => {
    setState((prev) => ({
      ...prev,
      newDesc: text,
    }));
  };

  const handleCopy = () => {
    toast.success("Text copied to clipboard!");
    navigator.clipboard.writeText(state.newDesc);
  };

  return (
    <div>
      <Modal
        size="lg"
        show={state.open}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="pt-5 pl-5 add-member_modal"
      >
        <div className="container">
          <div className="main-modal ai-modal">
            <Modal.Header className="project-modal_header">
              <Modal.Title>
                <img src={AI} alt="" />
                &nbsp;Summarize
              </Modal.Title>
              <FontAwesomeIcon icon={faClose} onClick={() => handleCancel()} />
            </Modal.Header>
            <Modal.Body className="project-modal_body">
              {loading ? (
                <div className="text-center">
                  <ClipLoader color="#0052cc" loading={loading} size={40} />
                </div>
              ) : (
                <>
                  <div className="row w-100">
                    <h5>AI Generated Description</h5>
                    <div className="edit__wiki__box_2 p-0 text-task editorviewtask ai-editor">
                      <CustomTextEditor
                        handleGetEditorState={handleGetEditorState}
                        description={data}
                        placeholder={"Enter task detail"}
                        isEditDescription={true}
                      />
                    </div>
                  </div>
                  <div className="actions">
                    <Link className="btn btn-cancel btn-sm" onClick={handleCancel}>
                      Cancel
                    </Link>
                    <div>
                      <button
                        className="btn btn-primary btn-sm copy-btn"
                        onClick={() => handleCopy()}
                      >
                        Copy to clipboard
                      </button>
                      <button
                        className="btn btn-primary btn-sm"
                        type="submit"
                        onClick={onSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AiModalWindow;
