import { autocomplete } from '@algolia/autocomplete-js';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { meilisearchAutocompleteClient } from '@meilisearch/autocomplete-client';
import React, { useEffect } from 'react';
import { api } from "../../customAxios";

const Search = () => {
    const client = new meilisearchAutocompleteClient({
        url: process.env.REACT_APP_MIELI_SEARCH_URL,
        apiKey: '79d0881d-951c-4419-8558-b7d9543cf6d3'
    });

    //   const FRONTEND_URL = 'http://localhost:3000/user/dashboard'

    const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
        key: 'RECENT_SEARCH',
        limit: 5,
    });

    useEffect(() => {

        autocomplete({
            container: '#search',
            placeholder: 'Search your tasks or bugs?',
            autoFocus: false,
            openOnFocus: true,
            plugins: [recentSearchesPlugin],
            onSubmit({ navigator, state }) {
                navigator.navigate({ itemUrl: `/user/dashboard/?search=${state.query}` })
            },
            initialState: {
                query: new URL(window.location).searchParams.get('search')
            },
            getSources() {
                return [
                    {
                        sourceId: 'Tasks',
                        getItems({ query }) {
                            const pathnameParts = window.location.pathname.split("/");
                            const pathName = pathnameParts[2];
                            const projectId = pathnameParts[3];

                            return api.get(`/search-all-indexes?query=${encodeURIComponent(query)}&indexName=Tasks${pathName === "project" ? `&project_id=${projectId}` : ''}`)
                                .then(data => {
                                    // Assume the API returns an array of items in the `results` property
                                    return data.data;
                                })
                                .catch(error => {
                                    console.error('Error fetching search results:', error);
                                    return [];
                                });
                        },
                        getItemUrl({ item }) {
                            return item.frontend_task_url;
                        },
                        templates: {
                            header({ html }) {
                                return html`
                                    <span className="aa-SourceHeaderTitle">Tasks</span>
                                    <div className="aa-SourceHeaderLine"></div>
                                `
                            },
                            item({ item, components, html }) {
                                return html`
                                    <a href="${item.hit?.frontend_task_url}" class="flex items-center space-x-2">
                                        <span>
                                            ${components.Highlight({
                                    hit: item.hit,
                                    attribute: 'name',
                                    tagName: 'em'
                                })}
                                        </span>
                                    </a>
                                `
                            }
                        }

                    },
                ];
            }
        });



    }, []);

    return <div id="search"></div>;
};



export default Search;
