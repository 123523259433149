import 'plyr/dist/plyr.css';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../../../componets/MainLayout/Footer';
import LandingNavbar from '../../../componets/MainLayout/LandingNavbar';

export default function FileViewMux() {
    const location = useLocation();
    const [file, setFile] = useState();

    useEffect(() => { // TODO: PUT IT IN NPM INSTAL
        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/@mux/mux-player';
        script.async = true;

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);


    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const assets_id = searchParams.get('assets_id');
        setFile(assets_id);
    }, []);

    return (
        <>
            <LandingNavbar />
            <div className='about-content'>
                <h2 className='text-center'>{`${process.env.REACT_APP_PROJECT_NAME}`} </h2>

                <div className="container__land">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10 mt-20">
                            <mux-player
                                playback-id={file}
                                metadata-video-title="Test VOD"
                                metadata-viewer-user-id="user-id-007"
                            ></mux-player>
                        </div>

                    </div>
                </div>

            </div>
            <Footer />

        </>
    )
}
