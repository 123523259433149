import React, { useEffect } from "react";
import All from "../../../assets/images/firstImage.png";
import { useInView } from "react-intersection-observer";

export default function Project() {

  const { ref: parent1Ref, inView: parent1InView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { ref: parent2Ref, inView: parent2InView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const { ref: parent3Ref, inView: parent3InView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    if (parent1InView || parent2InView || parent3InView) {
      document.body.classList.add("hide-scrollbar");
    } else {
      document.body.classList.remove("hide-scrollbar");
    }

    return () => {
      document.body.classList.remove("hide-scrollbar");
    };
  }, [parent1InView, parent2InView, parent3InView]);
  return (
    <div>
      <section className="project decrease__padding task-fearter-div">
        <div className="container__fearter">
          <div className="text-center" ref={parent1Ref}>
            <h3 className="project-class main-text responsive_heading">
              Task & Workflow Management
            </h3>
          </div>
          <div className="row mt-5 align-items-center">
            <div className={`col-md-6 cads__width`}>
              <div className={`cads ${parent1InView ? "animate__animated animate__fadeInLeft" : ""}`} style={{ visibility: parent1InView ? 'visible' : 'hidden', opacity: parent1InView ? 1 : 0 }}>
                <p className="paragraph responsive_small_heading">
                  <b>Tasks & Subtasks:</b>
                </p>
                <p className="pargraph-body mt-2 responsive_text">
                  Write user stories, break them into small subtasks, set
                  priorities, due date and assign them to team members.
                </p>
              </div>
              <br />
              <div className={`cads cads__center ${parent2InView ? "animate__animated animate__fadeInLeft" : ""}`} ref={parent2Ref} style={{ visibility: parent2InView ? 'visible' : 'hidden', opacity: parent2InView ? 1 : 0 }}>
                <p className="paragraph responsive_small_heading">
                  <b>Custom Statuses:</b>
                </p>
                <p className="pargraph-body mt-2 responsive_text">
                  Define unique statuses for your project, like 'in-review' and
                  'on-hold', to accurately reflect the current state of work.
                </p>
              </div>
              <br />
              <div className={`cads ${parent3InView ? "animate__animated animate__fadeInLeft" : ""}`} ref={parent3Ref} style={{ visibility: parent3InView ? 'visible' : 'hidden', opacity: parent3InView ? 1 : 0 }}>
                <p className="paragraph responsive_small_heading">
                  <b>Bugs Tracking:</b>
                </p>
                <p className="pargraph-body mt-2 responsive_text">
                  Efficiently track and manage bugs directly within tasks and
                  resolve them alongside other task-related activities.
                </p>
              </div>
            </div>
            <div className={`col-md-6 cads__colmn fearter-image ${parent3InView || parent1InView ? "animate__animated animate__fadeInRight" : ""}`} style={{ visibility: parent3InView || parent1InView ? 'visible' : 'hidden', opacity: parent3InView || parent1InView ? 1 : 0 }}>
              <div className="crop-container">
                <img src={All} alt="Cropped" className="cropped-image" />
                {/* <div className="image"></div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
