import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { api } from "../../../../customAxios";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
function ExportProjectModal(props) {
  const { open, setOpen } = props;
  const { id } = useParams();
  const [selectedOption, setSelectedOption] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const options = [
    { value: "scrumslate", label: "Scrum slate" },
    { value: "freedcamp", label: "Freedcamp" },
    // { value: "jira", label: "Jira" },
  ];

  const handleSelectImportOption = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleExportProject = () => {
    setIsLoading(true);
    api
      .get(`/project/${id}/export?type=${selectedOption}`)
      .then((response) => {
        setIsLoading(false);
        toast.success("Project export successfully!");
        const fileUrl = response.data.file;
        const downloadLink = document.createElement("a");
        downloadLink.href = fileUrl;
        const urlSegments = fileUrl.split("/");

        const fileName = urlSegments[urlSegments.length - 1];

        downloadLink.download = fileName;
        downloadLink.click();
        setOpen(false);
        // // Clean up resources
        // URL.revokeObjectURL(downloadLink.href);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error exporting project:", error);
      });
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedOption("");
  };
  return (
    <div>
      <Modal
        size="lg"
        show={open}
        // onHide={handleCancel}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="pt-5 pl-5 add-member_modal"
      >
        <div className="container">
          <div className="main-modal">
            <Modal.Header className="project-modal_header">
              <Modal.Title>Export Project</Modal.Title>
              <FontAwesomeIcon icon={faClose} onClick={() => handleCancel()} />
            </Modal.Header>
            <Modal.Body className="project-modal_body">
              <div className="fields">
                <h5 className="mb-4">Export From</h5>
                <div className="mb-10">
                  {options.map((option, index) => (
                    <div
                      className="form-check form-check-inline"
                      key={index + 1}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id={index + 1}
                        value={option.value}
                        onChange={handleSelectImportOption}
                      />
                      <label className="form-check-label" htmlFor={index + 1}>
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="actions">
                  <button
                    type="button"
                    className="btn btn-cancel btn-sm"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={`btn btn-primary btn-sm ${
                      isLoading ? "add-task-cliper-pd" : ""
                    }`}
                    onClick={handleExportProject}
                  >
                    {isLoading ? (
                      <ClipLoader color="#fff" size={20} />
                    ) : (
                      "Export"
                    )}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ExportProjectModal;
