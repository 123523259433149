import Plyr from 'plyr-react';
import 'plyr/dist/plyr.css';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Footer from '../../../componets/MainLayout/Footer';
import LandingNavbar from '../../../componets/MainLayout/LandingNavbar';
import { api } from '../../../customAxios';


export default function FileView() {
    const location = useLocation();
    const [file, setFile] = useState();
    let { id } = useParams();
    useEffect(() => {
        let searchParams = new URLSearchParams(location.search);
        searchParams = searchParams.get('filename');
        if (!searchParams) {
            searchParams = id;
        }
        const filename = atob(searchParams);
        api.get(`/get-s3-file?file_name=${filename}&file_type=${filename?.split('.').pop()}`)
            .then((response) => {
                setFile(response?.data);
            })
            .catch(error => {
                console.error('Error getting signed URL:', error);
            });
    }, []);
    const VideoPlayer = (fileUrl) => {
        const isVideo = fileUrl?.toLowerCase().includes('.mp4');
        const isAudio = fileUrl?.toLowerCase().includes('.mp3');

        return (
            <div className="row mb-20">
                {isVideo && (
                    <Plyr source={{ type: 'video', sources: [{ src: fileUrl, type: 'video/mp4' }] }} />

                )}

                {isAudio && (
                    <audio controls>
                        <source src={fileUrl} type="audio/mp3" />
                    </audio>
                )}
            </div>
        );
    }
    return (
        <>
            <LandingNavbar />
            <div className='about-content'>
                <h5 className='text-center'>{`${process.env.REACT_APP_PROJECT_NAME}  ${file?.file_title}`}</h5>
                <div className="container__land">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10 mt-20">
                            {VideoPlayer(file?.signed_url)}
                        </div>

                    </div>
                </div>

            </div>
            <Footer />

        </>
    )
}
