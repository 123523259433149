import { Grid, Typography } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { fullDateFormate, getTimeAgo } from "../../../Utils/helpers";
import CustomAvatar from "../../../componets/comman/Task/CustomAvatar";
import ViewFileDrawerLoader from "../../../componets/pageLoaders/projectLoaders/ViewFileDrawerLoader";
import MediaFileCard from "../../../componets/shared/MediaFileCard";
import { api } from "../../../customAxios";
import TaskComments from "../../../pages/Private/Projects/Tasks/TaskComments";
function ViewFileSideDrawer(props) {
  const { setProgress } = props;
  const [file, setFile] = useState();
  const { fileId } = useParams();
  const [fileTitle, setFileTitle] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const fileData = () => {
    setIsLoading(true);
    if (fileId) {
      api
        .get(`/files/${fileId}`)
        .then((response) => {
          setFile(response.data);
          setIsLoading(false);
        })
        .catch(() => {
          toast.error("Oops! something went wrong.");
        });
    }
  };
  useEffect(() => {
    fileData();
  }, [fileId]);

  const handleOpenTaskDrawer = (fileSource) => {
    window.open(fileSource);
  };
  const handleCloseDrawer = () => {
    const currentUrl = location.pathname;
    const newUrl = currentUrl.replace(`/panel/${fileId}`, "");
    navigate(newUrl);
  };

  return (
    <>
      <Drawer
        variant="temporary"
        anchor="right"
        className="sub-task-drawer "
        open={fileId ? true : false}
        onClose={handleCloseDrawer}
        style={{ zIndex: "1000" }}
      >
        <div className="sub-task">
          <Grid className="d-flex align-center ml-20 mt-20 cursor-hover">
            <Typography
              onClick={handleCloseDrawer}
              onKeyPress={handleCloseDrawer}
              tabIndex={0}
              className="d-flex align-center cursor-hover"
            >
              <svg
                width="18px"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#808191"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </Typography>
            <Typography
              className="f-20 font-weight-600 ml-20"
              onClick={handleCloseDrawer}
            >
              Close
            </Typography>
          </Grid>
          {isLoading ? (
            <ViewFileDrawerLoader />
          ) : (
            <div className="container-fluid viewFile_container">
              <div className="row ">
                <div className="col-md-12">
                  <MediaFileCard
                    setProgress={setProgress}
                    setFileTitle={setFileTitle}
                    fileTitle={fileTitle}
                    recordedFiles={file}
                  />
                  <div className="row ">
                    {file?.source_link && (
                      <div className="row">
                        <div className="col-md-2">
                          <h5>source link:</h5>
                        </div>
                        <div className="col-md-10">
                          <div className="d-flex">
                            <div
                              className="file_source me-2 task_icon add_cursor"
                              onClick={() =>
                                handleOpenTaskDrawer(file.source_link)
                              }
                            ></div>
                            <Link
                              className="text-decoration-none add_cursor"
                              onClick={() =>
                                handleOpenTaskDrawer(file.source_link)
                              }
                            >
                              {file?.source_link}
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row mt-3">
                      <div className="col-md-2">
                        <h5>Date / Time :</h5>
                      </div>
                      <div className="col-md-10">
                        <p className="text-secondary">
                          {fullDateFormate(file?.created_at)} (
                          {getTimeAgo(file?.created_at)})
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <h5>Created By:</h5>
                      </div>
                      <div className="col-md-10 user-div d-flex align-items-center">
                        {file?.created_by_image ? (
                          <div
                            className="size_cover file_by_user"
                            style={{
                              backgroundImage: `url('${file?.created_by_image}')`,
                            }}
                          ></div>
                        ) : (
                          <CustomAvatar
                            name={file?.created_by_name}
                            backgroundColor={
                              file?.created_by_profile_color || ""
                            }
                          />
                        )}
                        <span className="ms-2  text-capitalize">
                          {file?.created_by_name}
                        </span>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-8">
                        <nav className="nav_prob">
                          <div
                            className="nav nav-tabs"
                            id="nav-tab"
                            role="tablist"
                          >
                            <button
                              className="nav-link active"
                              id="nav-home-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-home"
                              type="button"
                              role="tab"
                              aria-controls="nav-home"
                              aria-selected="true"
                            >
                              <b>Comments</b>
                            </button>
                          </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                          <div
                            className="tab-pane fade show active pt-2 editorviewtask commenteditor"
                            id="nav-home"
                            role="tabpanel"
                            aria-labelledby="nav-home-tab"
                          >
                            <TaskComments
                              taskDetail={file}
                              isFile={true}
                              fileDetail={file}
                              setProgress={setProgress}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
}
export default ViewFileSideDrawer;
