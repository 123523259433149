import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

function ExtensionFeatureCard({ image, content }) {
  const { ref: ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const [leftAnimationClass, setLeftAnimationClass] = useState("");
  const [rightAnimationClass, setRightAnimationClass] = useState("");

  useEffect(() => {
    if (inView) {
      setLeftAnimationClass("animate__animated animate__fadeInLeft");
      setRightAnimationClass("animate__animated animate__fadeInRight");
    }

    const timeout = setTimeout(() => {
      setLeftAnimationClass("");
      setRightAnimationClass("");
    }, 1000);

    return () => clearTimeout(timeout);
  }, [inView, image, content]);

  useEffect(() => {
    if (inView || image || content) {
      document.body.classList.add("hide-scrollbar");
    } else {
      document.body.classList.remove("hide-scrollbar");
    }

    return () => {
      document.body.classList.remove("hide-scrollbar");
    };
  }, [inView, image, content]);

  return (
    <div className="container-fluid py-4 mb-5" ref={ref} style={{ visibility: inView ? 'visible' : 'hidden', opacity: inView ? 1 : 0 }}>
      <div className="row">
        <div className={`col-md-6 ${leftAnimationClass}`}>
          <div className="extension_feature_card">
            <img src={image} className="w-100" alt="" />
          </div>
        </div>
        <div className={`col-md-6 ${rightAnimationClass}`}>
          <div className="extension_feature_text">
            <div className="">{content}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExtensionFeatureCard;
