import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import AboutCards from "./AboutCards";
import AboutContent from "./AboutContent";

export default function AboutUs() {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView) {
      document.body.classList.add("hide-scrollbar");
    } else {
      document.body.classList.remove("hide-scrollbar");
    }

    return () => {
      document.body.classList.remove("hide-scrollbar");
    };
  }, [inView]);
  return (
    <>
      <div className="aboutus_page mb-5">
        <div className="container__land">
          <div className="text-center top-text">
            <h1 className="responsive_heading">About Us</h1>
            <p className=" mt-1 responsive_text about_us">Home/About Us</p>
            <br />
          </div>
        </div>
      </div>
      <div ref={ref} className={`features_card_section ${inView ? "animate__animated animate__fadeInUp" : ""
        }`}>
        <div className="container__land">
          <div className="meta-cards">
            <div className="row landing_page_row" style={{ visibility: inView ? 'visible' : 'hidden', opacity: inView ? 1 : 0 }}>
              <div className="col-md-3 col-sm-6 col-sx-12 mb-3">
                <div class="card feature-cards py-5 padding_unset">
                  <div class="card-body text-center cad-para">
                    <h1 class="card-title titles text-primary">150+</h1>
                    <p class="card-text paragra">
                      Countries And <br /> Territories
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-sx-12 mb-3">
                <div class="card py-5 padding_unset feature-cards">
                  <div class="card-body text-center cad-para">
                    <h1 class="card-title titles text-primary">100K+</h1>
                    <p class="card-text paragra">
                      Teams Use For <br /> Project Management
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-sx-12 mb-3">
                <div class="card feature-cards py-5 padding_unset">
                  <div class="card-body text-center cad-para">
                    <h1 class="card-title titles text-primary">250+</h1>
                    <p class="card-text paragra">
                      Peoples Use For <br /> Project Management
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-sx-12 mb-3">
                <div class="card feature-cards py-5 padding_unset">
                  <div class="card-body text-center cad-para">
                    <h1 class="card-title titles text-primary">300+</h1>
                    <p class="card-text paragra">
                      Companies Use For <br /> Project Management
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AboutContent />
      <AboutCards />
    </>
  );
}
